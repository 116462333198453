.termsWrapper {
  height: 75vh;
  margin: 0 190px;
  background: #ffffff;
}

.termsContainer {
  padding: 35px 35px 80px;
  height: 100%;
}

.termsContent {
  max-height: calc(100% - 80px);
  overflow-x: auto;
}

.checkboxTerms {
  margin: 30px 0;
  color: #2e2eb4;
}

@media only screen and (min-width: 1201px) {
  .termsWrapper {
    width: calc(100% - 380px);
  }
}

@media only screen and (max-width: 1200px) {
  .termsWrapper {
    margin: 0 30px;
    width: calc(100% - 60px);
  }
}

@media only screen and (max-width: 1023px) {
  .termsWrapper {
    margin: 5% 30px;
  }
}

@media only screen and (max-width: 600px) {
  .termsWrapper {
    margin: 0;
    width: 100%;
    height: 100vh;
  }
}
