.homeWrapper.ant-layout {
  min-height: 100%;
  position: relative;
  font-family: "Exo", sans-serif;
  background: #ffffff;
}

.homeHeader.ant-layout-header {
  display: flex;
  justify-content: space-between;
  padding: 60px 88px;
  height: 229.76px;
  align-items: center;
  background: linear-gradient(90deg, #2e2eb4 0%, #216aad 100%);
}

.contentLayout.ant-layout-content {
  padding-top: 100px;
  height: calc(100% - 76px);
  width: 100%;
  margin: 0 auto;
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 0; /* if you use -1 you have to set to "window" the interactivity.detectsOn property */
}

.contentLayoutDashboard.ant-layout-content {
  padding-top: 0;
}

.ant-carousel .homeCarousel .slick-prev,
.ant-carousel .homeCarousel .slick-next {
  border: solid #000000;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 10px;
  width: 10px;
  height: 10px;
}

.ant-carousel .slick-arrow.slick-prev {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: -50px;
}

.ant-carousel .slick-arrow.slick-next {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: -50px;
}

.ant-carousel .homeCarousel .slick-dots-bottom {
  bottom: 0;
}

.ant-carousel .slick-slider {
  height: 900px;
}

.ant-carousel .homeCarousel .slick-dots li button {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #c4c4c4;
}

.ant-carousel .homeCarousel .slick-dots li {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}

.ant-carousel .homeCarousel .slick-dots li.slick-active {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fc9c1c;
}

.ant-carousel .homeCarousel .slick-dots li.slick-active button {
  background: #fc9c1c;
}

.contactForm.ant-form .ant-form-item {
  width: 100%;
}

.contactForm.ant-form .ant-input {
  color: #ffffff;
  opacity: 60%;
  background: transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  border: 0;
  border-bottom: 0.5px solid #ffffff;
  padding: 5px 11px;
}

.contactFormHome.ant-form .ant-input,
.contactFormHome.ant-form label {
  opacity: 1;
  background: transparent;
  color: #ffffff;
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
}

.contactFormHome.ant-form .ant-input {
  border-bottom: 2px solid rgba(188, 193, 198, 1);
}

.contactFormHome.ant-form .ant-form-item-explain-error {
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.03em;
}

.contactForm.ant-form .ant-input-affix-wrapper {
  color: #ffffff;
  background: transparent;
  border: 0;
  border-bottom: 0.5px solid #ffffff;
  padding: 0;
}

.contactForm.ant-form .ant-input-affix-wrapper .ant-input {
  border: 0;
}

.contactForm.ant-form .ant-input-affix-wrapper .ant-input-suffix {
  color: #c4c4c4;
  font-size: 14px;
  line-height: 19px;
}

.contactFormHome.ant-form .ant-input-affix-wrapper .ant-input-suffix {
  color: #c4c4c4;
  font-size: 28px;
  line-height: 34px;
}

.contactForm.ant-form
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #ffffff;
}

.contactForm.ant-form .ant-input-affix-wrapper:focus,
.contactForm.ant-form .ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.contactForm.ant-form
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: transparent;
  border: 0;
  border-bottom: 0.5px solid #ffffff;
  padding: 5px 11px;
  height: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.contactFormHome.ant-form
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: transparent;
  border: 0;
  border-bottom: 0.5px solid #ffffff;
  color: #ffffff;
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
}

.contactForm.ant-form .ant-select-selection-placeholder {
  color: #ffffff;
}

.contactForm.ant-form .ant-select-arrow {
  color: #ffffff;
  margin-top: -12px;
  font-size: 22px;
}

.contactForm.ant-form
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #ffffff;
  box-shadow: none;
}

.contactForm.ant-form
  .ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: transparent;
}

.contactForm.ant-form
  .organizationSelect.ant-form-item-has-error
  .ant-form-item-control-input-content {
  border: 0;
}

.contactForm.ant-form .ant-input:focus {
  box-shadow: none;
}

.contactForm.ant-form .ant-input::placeholder {
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.contactFormHome.ant-form .ant-input::placeholder {
  opacity: 1;
  background: transparent;
  color: rgba(188, 193, 198, 1);
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
}

.contactBtn.ant-btn {
  margin-top: 9px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  background-color: #ee8032;
  padding: 19px 59.5px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #ee8032;
  height: auto;
  width: 50%;
  white-space: break-spaces;
}

.contactBtnHome.ant-btn {
  background: rgba(229, 123, 24, 1);
  padding: 26px 40px;
  border-radius: 16px;
  gap: 10px;
  font-family: Inter, sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  max-width: 200px;
  width: 100%;
  max-height: 86px;
  height: 100%;
  border: none;
  margin-top: 60px;
}

.ant-menu-horizontal > .ant-menu-item:after {
  display: none;
}

.contactBtnHome.ant-btn:hover {
  background: rgba(255, 158, 27, 1);
  color: white;
}

.contactFormHome .ant-form-item {
  max-width: 825px;
  height: 120px;
  grid-gap: 20px;
  gap: 20px;
  color: #ffffff;
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  margin: 0;
  display: flex;
  align-items: flex-end;
}

.contactModal .contactFormHome .ant-form-item {
  max-width: 617px;
  height: 88px;
  grid-gap: 32px;
  gap: 32px;
  color: #ffffff;
  font-family: Inter, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.03em;
  margin: 0;
  display: flex;
  align-items: flex-end;
}

.contactForm.ant-form {
  display: flex;
  flex-direction: column;
}

.contactForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.contactForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper,
.contactForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.contactForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:hover {
  background-color: transparent;
  border-color: transparent;
}

.contactForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.contactForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:focus,
.contactForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
.contactForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper-focused {
  border-color: transparent;
  box-shadow: none;
}

.contactForm.ant-form
  .ant-form-item-has-error
  .ant-form-item-control-input-content {
  border-bottom: 0.5px solid #ff4d4f;
  border-radius: 2px;
}

.contactForm.ant-form
  .inputCheckbox.ant-form-item-has-error
  .ant-form-item-control-input-content {
  border-bottom: 0;
}

@media screen and (max-width: 1500px) {
  .ant-carousel .slick-slider {
    height: 780px;
  }
}

@media screen and (max-width: 1280px) {
  .contactFormHome .ant-form-item {
    max-width: 554px;
    height: 80px;
    font-size: 22px;
    line-height: 28px;
  }

  .ant-carousel .slick-slider {
    height: 650px;
  }

  .contactFormHome.ant-form .ant-input,
  .contactFormHome.ant-form label,
  .contactFormHome.ant-form .ant-input-affix-wrapper .ant-input-suffix,
  .contactFormHome.ant-form
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  .contactFormHome.ant-form .ant-input::placeholder {
    font-size: 22px;
    line-height: 28px;
  }

  .contactBtnHome.ant-btn {
    font-size: 22px;
    line-height: 28px;
    margin-top: 32px;
  }
}

@media screen and (max-width: 1050px) {
  .contactFormHome .ant-form-item {
    max-width: 100%;
  }
}

@media screen and (max-width: 390px) {
  .contactFormHome .ant-form-item {
    max-width: 358px;
  }
}

@media screen and (max-width: 1200px) {
  .ant-carousel .slick-slider {
    height: 650px;
  }
}

@media screen and (max-width: 1023px) {
  .homeHeader.ant-layout-header {
    padding: 20px;
  }

  .ant-carousel .slick-arrow.slick-next {
    right: 20px;
    top: 10%;
  }
  .ant-carousel .slick-slider {
    height: 550px;
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item.headerMenuElementHome {
    height: 60px;
    line-height: 60px;
  }
}

@media screen and (max-width: 800px) {
  .ant-carousel .slick-slider {
    height: 450px;
  }
}

@media screen and (max-width: 600px) {
  .contactForm.ant-form .ant-form-item,
  .contactBtn.ant-btn {
    width: 100%;
  }

  .ant-carousel .homeCarousel .slick-dots-bottom {
    bottom: 0;
  }

  .ant-carousel .slick-arrow.slick-next {
    top: 30%;
  }

  .ant-carousel .slick-slider {
    height: 400px;
  }
}

@media screen and (max-width: 500px) {
  .ant-carousel .slick-slider {
    height: 350px;
  }
}
