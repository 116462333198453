.app {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 500px;
  overflow: hidden;
  position: relative;
}

.video {
  width: 500px;
  height: 500px;
}

.videoWrapper {
  width: 100%;
  height: 560px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  max-height: 560px;
}

.videosBlockKnowledgeBank {
  max-height: 785px;
  height: 100%;
}

.durationControl,
.volumeControl {
  border-radius: 4px;
  -webkit-appearance: none;
  height: 12px;
  outline: none;
  background: rgba(255, 255, 255, 0.3);
}

.durationControl {
  width: 90%;
  margin-right: 20px;
}

.volumeControl {
  width: 90%;
  margin-left: 10px;
}

.durationControl,
.volumeControl,
.durationControl::-webkit-slider-runnable-track,
.volumeControl::-webkit-slider-runnable-track,
.durationControl::-webkit-slider-thumb,
.volumeControl::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.durationControl::-webkit-slider-runnable-track,
.volumeControl::-webkit-slider-runnable-track {
  height: 12px;
  background: linear-gradient(to right, #fc9c1c, #fc9c1c),
    rgba(255, 255, 255, 0.3);
  background-size: var(--background-size, 0%) 100%;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.durationControl::-webkit-slider-thumb,
.volumeControl::-webkit-slider-thumb {
  width: 18px;
  height: 18px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 50%;
  margin-top: -3px;
}

/** FF*/

.durationControl::-moz-range-progress,
.volumeControl::-moz-range-progress {
  background-color: #fc9c1c;
  border-radius: 5px;
  height: 12px;
}

.durationControl::-moz-range-track,
.volumeControl::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  height: 12px;
}

.durationControl::-moz-range-thumb,
.volumeControl::-moz-range-thumb {
  width: 18px;
  height: 18px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 50%;
  margin-top: -6px;
  border: none;
}

@media screen and (max-width: 1700px) {
  .videoWrapper {
    height: 510px;
  }

  .videosBlockKnowledgeBank {
    max-height: 785px;
    height: 100%;
  }
}

@media screen and (max-width: 1540px) {
  .videoWrapper {
    height: 450px;
  }

  .videosBlockKnowledgeBank {
    max-height: 785px;
    height: 100%;
  }
}

@media screen and (max-width: 1400px) {
  .videoWrapper {
    height: 400px;
  }

  .videosBlockKnowledgeBank {
    max-height: 785px;
    height: 100%;
  }
}

@media screen and (max-width: 1280px) {
  .videoWrapper {
    height: 320px;
  }
  .videosBlockKnowledgeBank {
    max-height: 785px;
    height: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .videoWrapper {
    height: 100%;
    max-height: 560px;
  }
}

@media screen and (max-width: 600px) {
  .videoWrapper {
    margin-bottom: 60px;
  }
}
