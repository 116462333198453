.headerContent {
  max-width: 1900px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  align-items: center;
  padding: 0 44px;
}

.logo {
  padding: 5px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logoImg {
  width: 200px;
  height: 67px;
}

.logoTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 60px;
  color: #ffffff;
  margin-left: 10px;
}

.logoTitleBlue {
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 60px;
  color: #2e2eb4;
  margin-left: 10px;
}

.searchBtn {
  display: flex;
  background-color: rgba(255, 255, 255, 0.2);
  width: 52px;
  height: 52px;
  padding: 14px;
  border-radius: 300px;
  gap: 10px;
}

.headerSearch {
  position: absolute;
  top: -25px;
  right: -10px;
  width: 611px;
  z-index: 1;
}

.searchCloseBtn {
  position: absolute;
  right: 10px;
  cursor: pointer;
  z-index: 1;
}

.searchResults {
  background: linear-gradient(90.14deg, #2e2eb4 0.03%, #2165ad 99.78%);
}

.searchResultsItem {
  border-top: 0.5px solid #ffffff;
  border-bottom: 0.5px solid #ffffff;
}

.searchResultsItem:nth-child(2n) {
  border-top: none;
}

.logoutLink {
  font-family: Exo, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}

@media only screen and (max-width: 1320px) {
  .headerSearch {
    right: 110px;
    width: 600px;
  }

  .headerContent {
    padding: 0;
  }
}
