.headerContent {
  max-width: 1920px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  align-items: center;
}

.topSectionContainer,
.contactSectionContainer,
.whoNeedsSectionContainer,
.whoNeedsSectionContainerButton,
.meetLariContainer,
.whoNeedsSectionContainerBlock {
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.topSectionContainer {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

.whoNeedsSectionContainer {
  margin-top: 40px;
  gap: 40px;
}

.meetLariContainer {
  justify-content: space-between;
  align-items: start;
  gap: 32px;
  flex-direction: column;
}

.whoNeedsSectionBgIcon {
  position: absolute;
  right: 5%;
  top: 44%;
  width: 55%;
  transform: rotate(180deg);
}

.whoNeedsSectionContainerButton,
.whoNeedsSectionContainerBlock {
  gap: 40px;
  z-index: 1;
}

.videoSectionContainer,
.sliderSectionContent {
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
}

.logo {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.logoImgMain {
  width: 112px;
  height: 110px;
}

.logoTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 60px;
  color: #ffffff;
  margin-left: 10px;
}

.topSection {
  background: linear-gradient(0deg, #216bad 0.69%, #2e2eb4 65.03%);
  padding: 155px 88px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.whatIsLariSection {
  background: linear-gradient(180deg, #216bad 0.69%, #2e2eb4 65.03%);
  height: 100vh;
  position: relative;
}

.whoNeedsSection {
  height: 100%;
  position: relative;
}

.whoNeedsSectionTop {
  background: linear-gradient(0deg, #216bad 0.69%, #2e2eb4 65.03%);
  padding: 90px 88px;
}

.whoNeedsSectionButton {
  background: linear-gradient(180deg, #216bad 0.69%, #2e2eb4 65.03%);
  padding: 60px 88px 156px 88px;
  gap: 40px;
  display: flex;
  flex-direction: column;
}

.meetLariSection {
  background: linear-gradient(180deg, #216bad 0.69%, #2e2eb4 65.03%);
  padding: 110px 88px;
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.topSectionLeft {
  border: 2px solid rgba(255, 158, 27, 1);
  max-width: 727px;
  max-height: 560px;
  width: 36%;
  height: 560px;
  padding: 40px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topSectionRight {
  max-width: 980px;
  width: 64%;
  max-height: 560px;
  height: 560px;
}

.topSectionTitle {
  font-size: 42px;
  line-height: 48px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 42px;
}

.topSectionContent {
  font-size: 17px;
  line-height: 22px;
  color: #fc9c1c;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 700;
}

.topSectionSecondContent {
  font-size: 17px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 25px;
  text-align: center;
  font-weight: 600;
}

.topSectionLine {
  margin: 0 auto;
  display: block;
}

.topSectionBtns {
  display: flex;
  justify-content: space-between;
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  margin: 0 auto;
  text-align: center;
}

.signUpBtn,
.signInBtn {
  width: 160px;
  height: 58px;
  padding: 12px 24px 12px 24px;
  border-radius: 16px;
  gap: 10px;
  text-align: center;
}

.signUpBtnHome {
  width: 200px;
  height: 86px;
  padding: 26px 40px;
  border-radius: 16px;
  background: rgba(229, 123, 24, 1);
  color: #ffffff;
}

.signUpBtn {
  background: #e57b18;
  color: #ffffff;
  margin: 0 24px !important;
  border: 2px solid #e57b18;
  text-align: center !important;
}

.signUpBtn:hover,
.signUpBtnHome:hover {
  background: #ff9e1b;
  border: 2px solid #ff9e1b;
}

.signInBtn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.signInBtn {
  border: 2px solid rgb(255, 255, 255);
}

.videoSection {
  padding: 100px;
  background: #ffffff;
}

.videoSectionTitle {
  font-size: 64px;
  line-height: 85px;
  color: #000000;
}

.videoSectionContent {
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  width: 50%;
  margin-bottom: 80px;
}

.videosWrapper {
  display: flex;
  justify-content: space-between;
}

.videosWrapperRow {
  width: 49%;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.videosWrapperCol {
  height: 49%;
  max-height: 230px;
}

.contactSection {
  background: linear-gradient(0deg, #216bad 0.69%, #2e2eb4 65.03%);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 60px;
  margin-bottom: 76px;
  position: relative;
}

.contactSectionLeft {
  width: 50%;
}

.contactSectionIcon {
  max-width: 1035px;
  max-height: 900px;
  height: 100%;
  width: 50%;
}

.contactSectionTitle {
  font-size: 40px;
  line-height: 53px;
  color: #ffffff;
  margin-bottom: 22px;
  font-weight: 400;
}

.whoNeedsSectionTitle {
  font-size: 42px;
  line-height: 48px;
  color: #ffffff;
  margin-bottom: 15px;
  font-weight: 500;
}

.meetLariTitle {
  font-size: 42px;
  line-height: 48px;
  color: #ffffff;
  margin-bottom: 23px;
  font-weight: 500;
}

.meetLariOrangeText {
  font-size: 16px;
  line-height: 22px;
  color: #fc9c1c;
  font-weight: 600;
}

.meetLariWhiteText {
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  font-weight: 600;
  width: 80%;
}

.meetLariBlockWrapper {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.meetLariRightBlockWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 80px;
}

.meetLariBgIcon {
  position: absolute;
  top: -16%;
  left: 20%;
  width: 64%;
}

.meetLariImageWrapper {
  background: rgba(255, 255, 255, 1);
  width: 156px;
  height: 156px;
  padding: 33px 22px 33px 22px;
  border-radius: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whoNeedsSectionOrangeText {
  font-size: 14px;
  line-height: 19px;
  color: #fc9c1c;
  font-weight: 500;
}

.meetLariItemWrapper {
  max-width: 842px;
  width: 100%;
  display: flex;
  gap: 60px;
}

.whoNeedsSectionWhiteText {
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  font-weight: 500;
}

.whoNeedsSectionWhiteTextInBlock {
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 36px;
  max-width: 619px;
  width: 100%;
}

.whoNeedsSectionOrangeTextInBlock {
  font-size: 14px;
  line-height: 19px;
  color: #fc9c1c;
  font-weight: 500;
}

.whoNeedsSectionWhiteTextInBlockSimple {
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  font-weight: 500;
}

.whoNeedsSectionBlueText {
  font-size: 14px;
  line-height: 19px;
  color: #bae3fe;
  font-weight: 500;
}

.whoNeedsSectionVioletText {
  font-size: 14px;
  line-height: 19px;
  color: #a5a5e8;
  font-weight: 500;
}

.whoNeedsSectionVioletTitle {
  background: rgba(165, 165, 232, 1);
  padding: 10px 32px 10px 32px;
  border-radius: 21px 21px 0px 0px;
}

.whoNeedsSectionBlueTitle {
  background: rgba(102, 168, 226, 1);
  padding: 10px 32px 10px 32px;
  border-radius: 21px 21px 0px 0px;
}

.whoNeedsSectionOrangeTitle {
  background: rgba(255, 158, 27, 1);
  padding: 10px 32px 10px 32px;
  border-radius: 21px 21px 0px 0px;
}

.whoNeedsSectionTextBlock {
  padding: 24px;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.whoNeedsSectionOrangeWrapper {
  height: 760px;
  border: 2px solid rgba(255, 158, 27, 1);
  border-radius: 24px;
  width: 33.3%;
}

.whoNeedsSectionBlockInfoWrapper {
  height: 170px;
  border: 2px solid #ffffff;
  border-radius: 24px;
  width: 33.3%;
  padding: 32px;
  display: flex;
  gap: 24px;
  align-items: center;
}

.whoNeedsSectionTextWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.whoNeedsSectionImage {
  width: 156px;
  height: 156px;
  background-color: #d9d9d9;
  border-radius: 50%;
}

.whoNeedsSectionBlueWrapper {
  height: 760px;
  border: 2px solid rgba(102, 168, 226, 1);
  border-radius: 24px;
  width: 33.3%;
}

.whoNeedsSectionVioletWrapper {
  height: 760px;
  border: 2px solid rgba(165, 165, 232, 1);
  border-radius: 24px;
  width: 33.3%;
}

.contactSectionContent {
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 33px;
}

.contactSectionInfo {
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 20px;
}

.contactSectionInfoThin {
  color: rgba(255, 255, 255, 0.5);
}

.contactSectionRight {
  max-width: 825px;
  width: 50%;
  padding: 30px 88px 60px 0;
  gap: 24px;
}

.whatIsLariTitle {
  font-family: Exo, sans-serif;
  font-size: 101px;
  font-weight: 700;
  line-height: 121px;
  letter-spacing: -0.03em;
  text-align: center;
  color: #ffffff;
  margin: 50px 0;
}

.titlePaddingTop {
  padding-top: 150px;
  opacity: 0;
  animation: moveInAnimationFromTop 1s ease-out 2s forwards;
}

.bgIcon1Animation {
  opacity: 0;
  animation: moveInAnimationFromTop 1s ease-out 0.5s forwards;
}

.whatIsLariSecondSlide {
  position: absolute;
  width: 98%;
  left: 1%;
}

.titlePaddingBottom {
  padding-bottom: 150px;
  opacity: 0;
  animation: moveInAnimation 1s ease-out 0.5s forwards;
}

.bgIcon2Animation {
  opacity: 0;
  animation: moveInAnimation 1s ease-out 1.5s forwards;
}

.whatIsLariTitleSecond {
  font-size: 96px;
  line-height: 116px;
  color: #ffffff;
  margin-bottom: 50px;
  font-weight: 600;
  text-align: center;
  width: 60%;
}

.whatIsLariWhiteText {
  font-size: 80px;
  line-height: 100px;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
}

.hidden {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.5s,
    visibility 0.5s;
}

.whatIsLariOrangeText {
  font-size: 80px;
  line-height: 100px;
  color: #fc9c1c;
  font-weight: 400;
  text-align: center;
}

.whatIsLariContainer {
  display: flex !important;
  position: relative;
}

.whatIsLariContainerSecondBlock {
  background: rgba(46, 46, 180, 1);
  position: relative;
}

.whatIsLariInfoItem {
  display: flex !important;
  justify-content: center;
  width: 100%;
  gap: 70px;
}

.whatIsLariContainerThirdBlock {
  background: radial-gradient(
    79.56% 79.56% at 50% 50%,
    #b2d2f0 0%,
    #478fd2 100%
  );
  padding: 284px 0;
  position: relative;
}

.whatIsLariContainerThirdBlockIcon1 {
  position: absolute;
  top: 7%;
  left: 4%;
  max-width: 334px;
  max-height: 327px;
  height: 100%;
  width: 100%;
}

.whatIsLariContainerThirdBlockIcon2 {
  position: absolute;
  bottom: 10%;
  right: 3%;
  transform: rotate(260deg);
  max-width: 334px;
  max-height: 327px;
  height: 100%;
  width: 100%;
}

.whatIsLariThirdBlockText {
  font-size: 96px;
  line-height: 116px;
  color: #2e2eb4;
  font-weight: 400;
  text-align: center;
}

.topSectionBlueLine {
  padding: 29px 0;
  margin: 0 auto;
  max-width: 234px;
  width: 100%;
  z-index: 1;
  position: relative;
}

.whatIsLariBlockItem {
  display: flex;
  flex-direction: column;
}

.whatIsLariInfoBlock {
  padding: 174px 0 154px;
  z-index: 1;
}

.whatIsLariBgIcon {
  max-width: 776px;
  width: 45%;
  z-index: 1;
}

.whatIsLariBgIconAnimation {
  animation: scaleRotate 1.5s cubic-bezier(0.25, 0.1, 0.25, 1) 0s 1 normal
    forwards;
}

/* .textAnimation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-family: Arial, sans-serif;
  width: 100%;
}

.topText {
  font-size: 24px;
  margin-bottom: 10px;
}

@keyframes showAndHideFromTop {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50%);
    opacity: 0;
  }
}

.showAndHideFromTop {
  animation: showAndHideFromTop 10s ease forwards;
}

@keyframes showAndHideFromBottom {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(50%);
    opacity: 0;
  }
}

.showAndHideFromBottom {
  opacity: 0;
  animation: showAndHideFromBottom 10s ease 2s forwards;
} */

.textAnimation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-family: Arial, sans-serif;
  width: 100%;
}

.topText,
.bottomText {
  display: none;
  opacity: 0;
}
@keyframes showAndHideFromTop {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  20% {
    transform: translateY(0);
    opacity: 1;
  }
  80% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50%);
    opacity: 0;
  }
}

@keyframes showAndHideFromBottom {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  20% {
    transform: translateY(0);
    opacity: 1;
  }
  80% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(50%);
    opacity: 0;
  }
}

.showTop {
  display: block;
  animation: showAndHideFromTop 13s ease forwards;
}

.showBottom {
  display: block;
  animation: showAndHideFromBottom 13s ease 2s forwards;
}

.bottomText {
  font-size: 18px;
}

@keyframes scaleRotate {
  0% {
    transform: rotate(-360deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

.whatIsLariFirstSlide {
  position: absolute;
  max-width: 100%;
  width: 100%;
  left: 1%;
  top: 17%;
}

.whatIsLariFirstSlide2 {
  position: absolute;
  max-width: 60%;
  width: 100%;
  top: -5%;
}

.whatIsLariOrangeTitle {
  color: #fc9c1c;
  font-weight: 400;
}

@keyframes wipeAnimationn {
  from {
    clip-path: inset(0 0 100% 0);
    opacity: 0;
  }
  to {
    opacity: 1;
    clip-path: inset(0 0 0 0);
  }
}

@keyframes wipeAnimationFromDown {
  from {
    clip-path: inset(100% 0 0 0);
    opacity: 0;
  }
  to {
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
}

.whatIsLariLine {
  margin: 0 auto;
}

.whatIsLariLineAnimation {
  opacity: 0;
  animation: wipeAnimationn 1s ease 2s forwards;
}

.whatIsLariLineVerticalDown {
  margin: 0 auto;
  margin-top: 50px;
  height: 50%;
}

.whatIsLariLineVerticalDownAnimation {
  opacity: 0;
  animation: wipeAnimationFromDown 1s ease 1.5s forwards;
}

.whatIsLariLineVerticalUp {
  margin: 0 auto;
  margin-bottom: 50px;
  height: 50%;
}

.whatIsLariLineVerticalUpAnimation {
  opacity: 0;
  animation: wipeAnimationn 1s ease 0.5s forwards;
}

.sliderSection {
  padding: 0 100px 100px;
  background: #ffffff;
}

.carouselElement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 120px;
  z-index: 1;
  margin: 0 5px;
}

.elementContent {
  position: relative;
  background: linear-gradient(180deg, #216aad 0%, #2e2eb4 100%),
    linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #ffffff, #ffffff), #ffffff;
  border-radius: 30px;
  width: 70%;
}

.elementTitle {
  font-size: 64px;
  line-height: 85px;
  color: #2e2eb4;
  width: 20%;
}

.elementInfo {
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  padding: 100px;
}

.elementNumber {
  font-size: 288px;
  line-height: 383px;
  color: rgba(252, 156, 28, 0.5);
  position: absolute;
  left: -12%;
  bottom: 25%;
  z-index: -1;
}

.newFirstTitle {
  font-family: Exo, sans-serif;
  font-size: 70px;
  font-weight: 400;
  line-height: 84px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.newFirstTitleBlock {
  font-family: Exo, sans-serif;
  font-size: 70px;
  font-weight: 400;
  line-height: 84px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.newSecondTitle {
  font-family: Exo, sans-serif;
  font-size: 55px;
  font-weight: 400;
  line-height: 66px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.newThirdTitleBold {
  font-family: Inter, sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.newThirdTitle {
  font-family: Inter, sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.newThirdTitleTextContact {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.newFourthTitleBold {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.whiteButton {
  border-bottom: 2px solid #ffffff;
  cursor: pointer;
  width: fit-content;
}

.newFourthTitleItalic {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  font-style: italic;
  margin: 0;
}

.newFourthTitleName {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.newFourthTitle {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.newMainText {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff !important;
  margin: 0;
}

.newMainTextBold {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.newFourthTitleBoldName {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.newMainTextSmallBold {
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.newMainTextSmall {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.orangeTitle {
  color: rgba(255, 158, 27, 1);
}

.blueTitle {
  color: rgba(102, 168, 226, 1);
}

.blueTitleText {
  color: rgba(46, 46, 180, 1);
  font-weight: 400;
  z-index: 1;
  position: relative;
}

.violetTitle {
  color: rgba(165, 165, 232, 1);
}

.newMainTextWidth {
  max-width: 982px;
  width: 100%;
}

.textAlign {
  text-align: center;
}

.whatIsLariContainerThirdBlockbg {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
}

.whatIsLariBgIconForMobile {
  display: none !important;
}

@keyframes wipeAnimation {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.animationForMainTitle {
  white-space: nowrap;
  overflow: hidden;
  width: 0;
  animation: wipeAnimation 0.5s steps(100, end) 1.5s forwards;
}

.lineBlueAnimation {
  opacity: 0;
  animation: wipeAnimationFromLeft 1s ease 1.5s forwards;
}

@keyframes wipeAnimationFromLeft {
  from {
    clip-path: inset(0 100% 0 0);
    opacity: 0;
  }
  to {
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
}

@keyframes moveInAnimation {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes moveInAnimationFromTop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes moveInAnimationFromBottom {
  0% {
    opacity: 0;
    transform: translateY(1000%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animationForSecondTitle {
  opacity: 0;
  animation: moveInAnimation 0.5s ease-out 3s forwards;
}

.blueFirstTextAnimation {
  opacity: 0;
  animation: moveInAnimationFromBottom 1s ease 0.5s forwards;
}

.blueSecondTextAnimation {
  opacity: 0;
  animation: moveInAnimationFromBottom 1s ease-out 2s forwards;
}

@media screen and (max-width: 1700px) {
  .topSectionLeft,
  .topSectionRight {
    height: 510px;
  }

  .topSectionLeft {
    padding: 20px;
  }
}

@media screen and (max-width: 1540px) {
  .topSectionLeft,
  .topSectionRight {
    height: 450px;
  }
}

@media screen and (max-width: 1500px) {
  .topSection {
    padding: 70px 88px 147px;
  }

  .whoNeedsSectionTop {
    padding: 50px 88px 42px;
  }

  .whatIsLariInfoBlock {
    padding: 120px 0;
  }

  .whatIsLariTitle {
    font-size: 89px;
    line-height: 100px;
  }

  .whatIsLariInfoBlock {
    padding: 150px 0;
  }

  .whatIsLariContainerThirdBlockIcon1,
  .whatIsLariContainerThirdBlockIcon2 {
    max-width: 270px;
  }

  .whatIsLariContainerThirdBlockIcon1 {
    top: 3%;
  }

  .whatIsLariContainerThirdBlockIcon2 {
    bottom: 3%;
  }

  .whatIsLariContainerThirdBlock {
    padding: 250px 0;
  }

  .topSectionContainer {
    gap: 30px;
  }

  .signUpBtnHome {
    width: 200px;
    max-height: 66px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 1400px) {
  .topSectionLeft,
  .topSectionRight {
    height: 400px;
  }
}

@media screen and (max-width: 1280px) {
  .contactSectionIcon {
    max-width: 674px;
    max-height: 586px;
  }

  .contactSection {
    align-items: flex-end;
  }

  .topSection {
    padding: 11px 88px 65px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .topSectionBtns {
    font-size: 26px;
    line-height: 34px;
  }

  .signUpBtnHome {
    width: 140px;
    height: 58px;
    padding: 12px 24px;
  }

  .contactSectionRight {
    max-width: 554px;
    padding: 40px 83px 96px 0;
  }

  .contactTitle {
    margin-bottom: 32px;
  }

  .whoNeedsSectionTop {
    padding: 40px 88px 46px 88px;
  }

  .whoNeedsSectionContainer {
    gap: 28px;
  }

  .whoNeedsSectionBgIcon {
    top: 48%;
    width: 55%;
    right: 0;
  }

  .meetLariSection {
    padding: 105px 88px 59px;
  }

  .whatIsLariTitle {
    font-size: 67px;
    line-height: 80px;
  }

  .whatIsLariLine {
    width: 22px;
  }

  .whatIsLariInfoBlock {
    padding: 100px 0;
  }

  .whatIsLariBgIcon {
    max-width: 517px;
  }

  .whatIsLariLineVerticalUp {
    margin-bottom: 30px;
  }

  .whatIsLariLineVerticalDown {
    margin-top: 30px;
  }

  .whatIsLariContainerThirdBlock {
    padding: 200px 0;
  }

  .whatIsLariContainerThirdBlockIcon1,
  .whatIsLariContainerThirdBlockIcon2 {
    max-width: 220px;
  }

  .topSectionLeft,
  .topSectionRight {
    height: 320px;
  }

  .topSectionLeft .textAlign {
    font-size: 16px;
    line-height: 20px;
  }

  .signUpBtnHome {
    font-size: 16px;
    line-height: 20px;
    height: 100%;
  }
}

@media screen and (max-width: 1655px) {
  .whoNeedsSectionOrangeWrapper,
  .whoNeedsSectionBlueWrapper,
  .whoNeedsSectionVioletWrapper {
    height: 750px;
  }

  .whoNeedsSectionBlockInfoWrapper {
    padding: 24px;
  }

  .whoNeedsSectionImage {
    width: 112px;
    height: 112px;
  }
}
@media screen and (max-width: 1540px) {
  .whoNeedsSectionOrangeWrapper,
  .whoNeedsSectionBlueWrapper,
  .whoNeedsSectionVioletWrapper {
    height: 550px;
  }

  .whoNeedsSectionBgIcon {
    top: 42%;
    width: 56%;
  }

  .meetLariSection {
    padding: 30px 88px 60px;
  }
}

@media screen and (max-width: 1410px) {
  .whoNeedsSectionOrangeWrapper,
  .whoNeedsSectionBlueWrapper,
  .whoNeedsSectionVioletWrapper {
    height: 630px;
  }
}

@media screen and (max-width: 1390px) {
  .whoNeedsSectionBlockInfoWrapper {
    flex-direction: column;
  }

  .whoNeedsSectionBlockInfoWrapper {
    height: 290px;
  }
}

@media screen and (max-width: 1310px) {
  .whoNeedsSectionOrangeWrapper,
  .whoNeedsSectionBlueWrapper,
  .whoNeedsSectionVioletWrapper {
    height: 730px;
  }
}

@media screen and (max-width: 1023px) {
  .whoNeedsSectionOrangeWrapper,
  .whoNeedsSectionBlueWrapper,
  .whoNeedsSectionVioletWrapper {
    width: 100%;
    height: 390px;
  }

  .whoNeedsSectionContainer {
    gap: 26px;
  }

  .whoNeedsSectionTop {
    padding: 20px 16px 20px 16px;
  }

  .whoNeedsSectionButton {
    padding: 14px 16px 37px 16px;
  }

  .contactSectionIcon {
    display: none;
  }

  .contactSectionRight {
    padding: 40px 16px 78px;
    width: 100%;
    max-width: 100%;
  }

  .whoNeedsSectionBgIcon {
    top: 64%;
    width: 54%;
    transform: rotate(18deg);
  }

  .meetLariImageWrapper {
    width: 116px;
    height: 116px;
  }

  .meetLariImage {
    width: 85px;
    height: 85px;
  }

  .meetLariBgIcon {
    width: 80%;
  }

  .meetLariSection {
    padding: 70px 16px 53px;
    gap: 20px;
  }

  .meetLariBlockWrapper,
  .meetLariRightBlockWrapper {
    gap: 24px;
  }

  .topSection {
    padding: 24px 16px 49px;
  }

  .topSectionLeft {
    max-width: 100%;
  }

  .topSectionContainer {
    gap: 40px;
  }

  .whatIsLariContainerThirdBlock {
    padding: 170px 0;
  }

  .whatIsLariContainerThirdBlockIcon1,
  .whatIsLariContainerThirdBlockIcon2 {
    max-width: 200px;
  }

  .meetLariBlockWrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 850px) {
  .whoNeedsSectionContainerBlock {
    flex-direction: column;
    gap: 26px;
  }

  .whoNeedsSectionBgIcon {
    top: 43%;
    width: 177%;
  }

  .whoNeedsSectionBlockInfoWrapper {
    width: 100%;
  }

  .meetLariImageWrapper {
    width: 86px;
    height: 86px;
  }

  .meetLariImage {
    width: 63px;
    height: 63px;
  }

  .meetLariBgIcon {
    width: 80%;
  }

  .meetLariContainer {
    flex-direction: column;
    gap: 40px;
  }

  .meetLariBgIcon {
    width: 180%;
    left: 0;
  }

  .whatIsLariInfoBlock {
    padding: 80px 0;
  }

  .whatIsLariContainerThirdBlock {
    padding: 150px 0;
  }

  .whatIsLariContainerThirdBlockIcon1,
  .whatIsLariContainerThirdBlockIcon2 {
    max-width: 180px;
  }

  .whatIsLariContainerThirdBlockIcon1 {
    top: 1%;
    max-height: 230px;
  }

  .whatIsLariContainerThirdBlockIcon2 {
    bottom: 1%;
    max-height: 230px;
  }
}

@media screen and (max-width: 390px) {
  .contactSectionRight {
    padding: 40px 16px 78px;
  }
}

@media screen and (max-width: 1430px) {
  .whatIsLariInfoBlock {
    width: 72%;
  }
}

@media screen and (max-width: 1350px) {
  .whatIsLariTitle {
    font-size: 100px;
    line-height: 113px;
  }

  .whatIsLariWhiteText,
  .whatIsLariOrangeText {
    font-size: 70px;
    line-height: 100px;
  }
}

@media screen and (max-width: 1315px) {
  .whatIsLariTitle {
    font-size: 95px;
    line-height: 113px;
  }

  .whatIsLariWhiteText,
  .whatIsLariOrangeText {
    font-size: 65px;
    line-height: 100px;
  }
}

@media screen and (max-width: 1315px) {
  .whatIsLariTitle {
    font-size: 89px;
    line-height: 100px;
  }

  .whatIsLariWhiteText,
  .whatIsLariOrangeText {
    font-size: 60px;
    line-height: 80px;
  }

  .whatIsLariInfoBlock {
    width: 64%;
  }

  .whatIsLariThirdBlockText {
    font-size: 90px;
    line-height: 110px;
  }
}

@media screen and (max-width: 1200px) {
  .signInBtn,
  .signUpBtn {
    width: 100%;
    margin-bottom: 20px;
  }

  .signUpBtn {
    padding: 7px 20px;
    height: 100%;
  }

  .topSection {
    align-items: normal;
  }

  .whoNeedsSectionOrangeText,
  .whoNeedsSectionWhiteText,
  .whoNeedsSectionBlueText,
  .whoNeedsSectionVioletText {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
  }

  .whatIsLariTitle {
    font-size: 75px;
    line-height: 90px;
  }

  .whatIsLariWhiteText,
  .whatIsLariOrangeText {
    font-size: 45px;
    line-height: 65px;
  }

  .whatIsLariBgIcon {
    max-width: 500px;
  }

  .whatIsLariTitleSecond {
    font-size: 80px;
    line-height: 90px;
  }
  .whatIsLariLineVerticalDown,
  .whatIsLariLineVerticalUp {
    width: 8%;
  }

  .whatIsLariThirdBlockText {
    font-size: 70px;
    line-height: 60px;
  }
}

@media screen and (max-width: 1023px) {
  .signUpBtn {
    margin: 0 !important;
  }
  .sliderSection {
    padding: 0 20px 100px;
  }

  .carouselElement {
    padding-top: 0;
    flex-direction: column;
    align-items: normal;
  }

  .elementTitle {
    width: 75%;
    margin-bottom: 110px;
  }

  .elementContent {
    width: 100%;
  }

  .elementInfo {
    padding: 30px;
  }

  .elementNumber {
    font-size: 144px;
    line-height: 191px;
    left: auto;
    right: 0;
    bottom: auto;
    top: -60%;
  }

  .contactSectionIcon {
    width: 65.5%;
  }

  .topSectionContainer {
    flex-direction: column;
  }

  .topSectionLeft {
    width: 100%;
  }

  .whoNeedsSectionContainer,
  .whoNeedsSectionContainerButton {
    flex-direction: column;
  }

  .whatIsLariTitle {
    font-size: 57px;
    line-height: 80px;
  }

  .whatIsLariWhiteText,
  .whatIsLariOrangeText {
    font-size: 40px;
    line-height: 60px;
  }

  .whatIsLariBgIcon {
    max-width: 450px;
  }

  .whatIsLariTitleSecond {
    font-size: 70px;
    line-height: 80px;
  }
  .whatIsLariLineVerticalDown,
  .whatIsLariLineVerticalUp {
    width: 10%;
  }

  .whatIsLariThirdBlockText {
    font-size: 50px;
    line-height: 35px;
  }

  .whatIsLariContainerThirdBlockIcon2,
  .whatIsLariContainerThirdBlockIcon1 {
    width: 25%;
  }

  .topSectionRight {
    height: 100%;
    max-height: 560px;
    width: 100%;
  }

  .topSectionLeft {
    width: 100%;
    height: 100%;
    max-height: 560px;
    gap: 20px;
  }

  .topSectionLeft .textAlign,
  .signUpBtnHome {
    font-size: 22px;
    line-height: 28px;
  }
}

@media screen and (max-width: 800px) {
  .topSection {
    padding: 20px;
    flex-direction: column;
    width: 100%;
  }

  .meetLariContainer {
    flex-direction: column;
  }

  .topSectionLeft,
  .topSectionRight {
    width: 100%;
  }

  .topSectionContent {
    margin-bottom: 25px;
  }

  .topSectionBtns {
    margin-top: 20px;
  }

  .contactSectionIcon {
    width: 87%;
  }

  .whatIsLariTitle {
    font-size: 40px;
    line-height: 60px;
  }

  .whatIsLariWhiteText,
  .whatIsLariOrangeText {
    font-size: 30px;
    line-height: 40px;
  }

  .whatIsLariBgIcon {
    max-width: 400px;
  }

  .whatIsLariTitleSecond {
    font-size: 60px;
    line-height: 70px;
  }
  .whatIsLariLineVerticalDown,
  .whatIsLariLineVerticalUp {
    width: 10%;
  }

  .whatIsLariThirdBlockText {
    font-size: 40px;
    line-height: 40px;
  }

  .whatIsLariContainerThirdBlockIcon2,
  .whatIsLariContainerThirdBlockIcon1 {
    width: 25%;
  }
  .whatIsLariContainerThirdBlock {
    padding: 140px;
  }

  .topSectionBlueLine {
    padding: 10px 0;
  }
}

@media screen and (max-width: 670px) {
  .whoNeedsSectionOrangeWrapper,
  .whoNeedsSectionBlueWrapper,
  .whoNeedsSectionVioletWrapper {
    height: 365px;
  }

  .whatIsLariBgIcon {
    display: none;
    max-width: 0;
  }

  .whatIsLariBgIconForMobile {
    display: block !important;
    position: relative;
    left: 0;
  }
  .whatIsLariInfoBlock {
    position: relative;
    right: 10%;
    padding: 50px 0;
  }
}

@media screen and (max-width: 600px) {
  .topSection {
    padding: 20px;
    flex-direction: column;
    width: 100%;
  }

  .topSectionLeft,
  .topSectionRight {
    width: 100%;
  }

  .topSectionContent {
    margin-bottom: 25px;
  }

  .videoSection {
    padding: 20px;
  }

  .videoSectionContent {
    width: 100%;
    margin-bottom: 40px;
  }

  .videosWrapper {
    flex-direction: column;
  }

  .videosWrapperRow {
    width: 100%;
  }

  .videosWrapperCol {
    height: auto;
    max-height: none;
  }

  .carouselElement {
    padding-top: 120px;
    flex-direction: column;
    align-items: normal;
  }

  .contactSectionIcon {
    width: 87%;
  }

  .topSectionContainer,
  .contactSectionContainer {
    flex-direction: column;
  }

  .whatIsLariTitle {
    font-size: 37px;
    line-height: 45px;
  }

  .whatIsLariWhiteText,
  .whatIsLariOrangeText {
    font-size: 20px;
    line-height: 30px;
  }

  .whatIsLariTitleSecond {
    font-size: 40px;
    line-height: 50px;
  }

  .whatIsLariThirdBlockText {
    font-size: 30px;
    line-height: 30px;
  }
}

@media screen and (max-width: 500px) {
  .carouselElement {
    padding-top: 120px;
    flex-direction: column;
    align-items: normal;
  }

  .elementNumber {
    top: -25%;
  }

  .contactSectionIcon {
    width: 100%;
  }

  .whatIsLariTitle {
    font-size: 25px;
    line-height: 45px;
  }

  .whatIsLariWhiteText,
  .whatIsLariOrangeText {
    font-size: 20px;
    line-height: 30px;
  }

  .whatIsLariTitleSecond {
    font-size: 30px;
    line-height: 40px;
  }

  .whatIsLariThirdBlockText {
    font-size: 30px;
    line-height: 30px;
  }

  .whatIsLariContainerThirdBlock {
    padding: 110px 0;
  }

  .whatIsLariContainerThirdBlockIcon1 {
    top: 1%;
    max-height: 150px;
  }

  .whatIsLariContainerThirdBlockIcon2 {
    bottom: 1%;
    max-height: 150px;
  }
}

@media screen and (max-width: 1540px) {
  .newFirstTitle {
    font-size: 54px;
    line-height: 64.99px;
  }

  .newFirstTitleBlock {
    font-size: 50px;
    line-height: 60px;
  }

  .newSecondTitle {
    font-size: 42px;
    line-height: 52px;
  }

  .newThirdTitleBold {
    font-size: 24px;
    line-height: 34.04px;
  }

  .newThirdTitle {
    font-size: 24px;
    line-height: 34.04px;
  }

  .newThirdTitleTextContact {
    font-size: 20px;
    line-height: 26px;
  }

  .newFourthTitleBold {
    font-size: 20px;
    line-height: 26px;
  }

  .newFourthTitleName {
    font-size: 20px;
    line-height: 26px;
  }

  .newFourthTitleBoldName {
    font-size: 20px;
    line-height: 26px;
  }

  .newFourthTitleItalic {
    font-size: 20px;
    line-height: 26px;
  }

  .newFourthTitle {
    font-size: 20px;
    line-height: 26px;
  }

  .newMainTextBold {
    font-size: 20px;
    line-height: 26px;
  }

  .newMainText {
    font-size: 20px;
    line-height: 26px;
  }

  .newMainTextSmallBold {
    font-size: 20px;
    line-height: 26px;
  }

  .newMainTextSmall {
    font-size: 20px;
    line-height: 26px;
  }
}

@media screen and (max-width: 670px) {
  .newFirstTitle {
    font-size: 38px;
    line-height: 44px;
  }

  .newFirstTitleBlock {
    font-size: 38px;
    line-height: 44px;
  }

  .newSecondTitle {
    font-size: 30px;
    line-height: 36px;
  }

  .newThirdTitleBold {
    font-size: 16px;
    line-height: 24px;
  }

  .newThirdTitle {
    font-size: 16px;
    line-height: 24px;
  }

  .newThirdTitleTextContact {
    font-size: 24px;
    line-height: 34px;
  }

  .newFourthTitleBold {
    font-size: 15px;
    line-height: 18.04px;
  }

  .newFourthTitleItalic {
    font-size: 15px;
    line-height: 18.04px;
  }

  .newFourthTitle {
    font-size: 15px;
    line-height: 18.04px;
  }

  .newMainTextBold {
    font-size: 14px;
    line-height: 19px;
  }

  .newMainText {
    font-size: 14px;
    line-height: 19px;
  }

  .newMainTextSmallBold {
    font-size: 13px;
    line-height: 18px;
  }

  .newMainTextSmall {
    font-size: 13px;
    line-height: 18px;
  }
}
