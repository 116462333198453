.leftWrapperContainer,
.rightWrapperContainer {
  max-width: 960px;
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0 auto;
}

.leftWrapperContainer {
  margin: auto 0 auto auto;
  padding: 10% 0 10% 100px;
}

.rightWrapperContainer {
  padding: 10% 0;
}

.leftWrapperContent {
  padding: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}

.leftWrapperTitle {
  margin-bottom: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 48px;
  color: #ffffff;
}

.leftWrapperText {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  display: block;
  width: 75%;
}

.rightWrapperContent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 10%;
  min-height: 100%;
}

@media only screen and (max-width: 1023px) {
  .leftWrapperContent {
    padding: 70px 30px 70px;
    margin: 0;
  }

  .rightWrapperContent {
    padding: 15%;
    margin: 0;
  }

  .leftWrapperContainer {
    margin: 0;
    padding: 0;
  }

  .rightWrapperContainer {
    margin: 0;
    padding: 0;
  }
}

@media only screen and (max-width: 600px) {
  .rightWrapperContent {
    padding: 10%;
    margin: 0;
  }
}
