.cookieBanner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  border-radius: 30px 30px 0 0;
  padding: 31px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
}

.cookieBannerText {
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

@media only screen and (max-width: 1024px) {
  .cookieBanner {
    flex-direction: column;
    padding: 31px 20px;
  }

  .cookieBannerText {
    margin-bottom: 20px;
  }
}
