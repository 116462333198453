.footer.ant-layout-footer {
  font-family: Inter, sans-serif;
  padding: 27px 90px;
  font-size: 18px;
  line-height: 22px;
  color: #5a5a5a;
  height: 76px;
  z-index: 5;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.footer.ant-layout-footer {
  background: linear-gradient(90deg, #2e2eb4 0%, #216aad 100%);
}

.footerContent {
  color: #ffffff;
}

@media screen and (max-width: 1320px) {
  .footerContent {
    padding: 0;
  }

  .footer.ant-layout-footer {
    padding: 27px 20px;
  }
}

@media screen and (max-width: 600px) {
  .footer.ant-layout-footer {
    padding: 20px;
  }
}
