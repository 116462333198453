.acceptBtn.ant-btn {
  border-radius: 10px;
  padding: 20px 60px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  height: auto;
}

.acceptBtn.ant-btn {
  margin-right: 15px;
  background: #fc9c1c;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  color: #ffffff;
}

.acceptBtn.ant-btn:hover {
  border: 1px solid #ffffff;
  color: #000000;
}
