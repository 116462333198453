.awlist1 {
  list-style: none;
  counter-reset: awlistcounter3_1;
}

.awlist1 > li:before {
  content: "(" counter(awlistcounter3_1, lower-roman) ")";
  counter-increment: awlistcounter3_1;
}

.termsTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-decoration-line: underline;
  color: #2e2eb4;
  padding-bottom: 30px;
}

.close {
  width: 18px;
  height: 18px;
  cursor: pointer;
  background-color: #f1f7fb00;
  border: none;
}

.close:hover {
  opacity: 0.3;
}

.close:before,
.close:after {
  position: absolute;
  content: " ";
  height: 18px;
  width: 2px;
  background-color: #333;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}
