.contactModal-header {
  display: flex;
  gap: 24px;
  padding: 40px 40px 36px;
  border-bottom: 2px solid rgba(188, 193, 198, 1);
}

.contactModal-header-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.contactModal-header-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contactModal-header-name {
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(255, 158, 27, 1);
  margin: 0;
}

.contactModal-header-title {
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  margin: 0;
}

.contactModal-block {
  padding: 36px 40px 32px;
}

.contactModal-block-title {
  font-family: Exo, sans-serif;
  font-size: 55px;
  font-weight: 400;
  line-height: 66px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 24px;
}

.contactModal .contactForm.ant-form {
  gap: 32px;
}

.contactModal textarea.ant-input {
  width: 617px;
  height: 120px;
  padding: 18px 16px !important;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 1) !important;
}

.contactModal-btnBlock {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.contactModal-btnOrange.ant-btn {
  background: rgba(229, 123, 24, 1);
  max-width: 293px;
  max-height: 58px;
  width: 100%;
  height: 100%;
  padding: 12px 24px;
  border-radius: 16px;
  gap: 10px;
  margin: 0;
  text-align: center;
  font-family: Inter;
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  color: rgba(255, 255, 255, 1);
}

.contactModal-btnOrange.ant-btn:hover {
  color: rgba(46, 46, 180, 1);
  border: 0.5px solid rgba(46, 46, 180, 1);
}

.contactModal-btnWhite.ant-btn {
  max-width: 293px;
  max-height: 58px;
  width: 100%;
  height: 100%;
  padding: 12px 24px;
  border-radius: 16px;
  gap: 10px;
  margin: 0;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  color: rgba(46, 46, 180, 1);
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
}

.contactModal-btnWhite.ant-btn:hover {
  color: rgba(229, 123, 24, 1);
  border: 0.5px solid rgba(229, 123, 24, 1);
}

@media only screen and (max-width: 1500px) {
  .contactModal .contactFormHome .ant-form-item {
    height: 60px;
  }

  .contactModal .contactModal-textarea.ant-form-item {
    height: 100px;
  }
}
