body,
#root,
.App {
  width: 100%;
  height: 100%;
}

html {
  scroll-behavior: smooth;
}
