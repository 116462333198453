.videoContainer {
  width: 100%;
  height: 560px;
  object-fit: cover;
  max-height: 560px;
}

.videoPlayButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.playButton {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.playIcon,
.pauseIcon,
.volumeIcon,
.fullScreenIcon,
.muteIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.controlsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: transparent;
  margin-top: 0;
  padding: 0 25px;
  position: absolute;
  bottom: 15px;
  height: 30px;
}

.timeControls {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
}

.duration {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.volumeControls {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20%;
}

.videoContainer:not(root):fullscreen {
  object-fit: contain;
}

.fullScreen {
  width: 40px;
  display: block;
  cursor: pointer;
  text-align: center;
}

.muteBtn {
  width: 40px;
  display: block;
  cursor: pointer;
  text-align: center;
}

.controls {
  width: 40px;
  display: block;
  cursor: pointer;
  text-align: center;
}

.videoContainer::-webkit-media-controls {
  display: none;
}

.videoContainer::-webkit-media-controls-enclosure {
  display: none;
}

@media screen and (max-width: 1700px) {
  .videoContainer {
    height: 510px;
  }
  .videosBlockKnowledgeBank {
    max-height: 785px;
    height: 100%;
  }
}

@media screen and (max-width: 1540px) {
  .videoContainer {
    height: 450px;
  }
  .videosBlockKnowledgeBank {
    max-height: 785px;
    height: 100%;
  }
}

@media screen and (max-width: 1400px) {
  .controlsContainer {
    padding: 0 50px;
  }

  .videoContainer {
    height: 400px;
  }
  .videosBlockKnowledgeBank {
    max-height: 785px;
    height: 100%;
  }
}

@media screen and (max-width: 1280px) {
  .videoContainer {
    height: 320px;
  }
  .videosBlockKnowledgeBank {
    max-height: 785px;
    height: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .timeControls {
    width: 50%;
  }

  .volumeControls {
    width: 30%;
  }
}

@media screen and (max-width: 1023px) {
  .videoContainer {
    height: 100%;
    max-height: 560px;
  }
}

@media screen and (max-width: 600px) {
  .playButton {
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 420px) {
  .timeControls {
    width: 40%;
  }

  .volumeControls {
    width: 35%;
  }
}
