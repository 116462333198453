.backLink {
  width: auto;
  position: absolute;
  top: 7%;
  left: 110px;
  font-size: 24px;
  line-height: 32px;
  color: #5a5a5a;
  padding-left: 30px;
}

.backLink:hover,
.backLink:hover:before {
  color: #2e2eb4;
}

.backLink::before {
  content: "";
  color: #5a5a5a;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  position: absolute;
  width: 11px;
  height: 11px;
  left: 5px;
  top: 10px;
  transform: rotate(135deg);
}

@media only screen and (max-width: 1023px) {
  .backLink {
    top: 20%;
  }
}

@media only screen and (max-width: 600px) {
  .backLink {
    top: 150px;
    left: 30px;
  }
}
