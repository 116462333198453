.inputPassword.ant-form-item {
  position: relative;
}

.inputPassword .ant-form-item-control-input-content {
  border-bottom: 0.5px solid #5a5a5a;
  border-radius: 2px;
}

.forgotPassword {
  position: absolute;
  right: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #2e2eb4;
  padding-bottom: 0;
  line-height: 36px;
}

@media only screen and (max-width: 1140px) {
  .forgotPassword {
    font-size: 14px;
  }

  .inputPassword.ant-form-item .ant-input {
    width: calc(100% - 140px);
  }
}

@media only screen and (max-width: 600px) {
  .forgotPassword {
    font-size: 14px;
  }
}
