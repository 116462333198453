.stepsCount {
  width: 100%;
  position: absolute;
  top: -5%;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
}

.stepBack {
  font-size: 24px;
  line-height: 32px;
  color: #5a5a5a;
  padding: 0 0 0 18px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.stepBack:hover,
.stepBack:active,
.stepBack:focus,
.stepBack:hover:before {
  color: #2e2eb4;
}

.stepBack::before {
  content: "";
  color: #5a5a5a;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  position: absolute;
  width: 11px;
  height: 11px;
  left: 100px;
  top: 10px;
  transform: rotate(135deg);
}

.termsShow {
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #2e2eb4;
  padding-left: 10px;
  cursor: pointer;
  position: relative;
}

.termsPromt {
  font-size: 10px;
  line-height: 15px;
  color: #ff4d4f;
  padding-left: 10px;
}

.termsShow:before {
  content: "";
  position: absolute;
  display: block;
  left: -40px;
  top: -5px;
  right: -5px;
  bottom: -5px;
}

.termsShow:hover {
  color: #2168ad;
  text-decoration: none;
}

.counter {
  font-size: 24px;
  line-height: 32px;
  color: #5a5a5a;
  margin-left: auto;
}

.inputCheckbox.ant-form-item {
  margin-top: 0;
}

.inputCheckbox .ant-checkbox-inner {
  width: 30px;
  height: 30px;
  border: 1px solid #5a5a5a;
  border-radius: 5px;
}

.inputCheckbox .ant-checkbox-checked::after {
  border-radius: 5px;
}

.inputCheckbox .ant-checkbox-inner::after {
  width: 10px;
  height: 17px;
}

.inputCheckbox .ant-checkbox-wrapper {
  align-items: center;
}

@media only screen and (max-width: 1330px) {
  .stepBack::before {
    left: 10%;
  }
}

@media only screen and (max-width: 1023px) {
  .stepsCount {
    top: 8%;
    padding: 0 15%;
  }

  .stepBack::before {
    left: 5%;
  }

  .stepBack {
    top: 250%;
    position: absolute;
  }

  .pageTitle {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 800px) {
  .pageTitle {
    margin-bottom: 50px;
    margin-top: 90px;
  }
}

@media only screen and (max-width: 600px) {
  .stepsCount {
    padding: 0 10%;
    position: initial;
  }

  .termsShow {
    padding-left: 0;
  }

  .inputCheckbox .ant-checkbox-wrapper {
    align-items: center;
  }

  .counter {
    position: absolute;
    right: 10%;
    top: 7%;
  }

  .stepBack {
    top: 20%;
    position: absolute;
    left: 10%;
  }
}
