@import url(https://fonts.googleapis.com/css2?family=Exo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body,
html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
#root,
.App {
  width: 100%;
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

.homeWrapper.ant-layout {
  min-height: 100%;
  position: relative;
  font-family: "Exo", sans-serif;
  background: #ffffff;
}

.homeHeader.ant-layout-header {
  display: flex;
  justify-content: space-between;
  padding: 60px 88px;
  height: 229.76px;
  align-items: center;
  background: linear-gradient(90deg, #2e2eb4 0%, #216aad 100%);
}

.contentLayout.ant-layout-content {
  padding-top: 100px;
  height: calc(100% - 76px);
  width: 100%;
  margin: 0 auto;
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 0; /* if you use -1 you have to set to "window" the interactivity.detectsOn property */
}

.contentLayoutDashboard.ant-layout-content {
  padding-top: 0;
}

.ant-carousel .homeCarousel .slick-prev,
.ant-carousel .homeCarousel .slick-next {
  border: solid #000000;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 10px;
  width: 10px;
  height: 10px;
}

.ant-carousel .slick-arrow.slick-prev {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: -50px;
}

.ant-carousel .slick-arrow.slick-next {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: -50px;
}

.ant-carousel .homeCarousel .slick-dots-bottom {
  bottom: 0;
}

.ant-carousel .slick-slider {
  height: 900px;
}

.ant-carousel .homeCarousel .slick-dots li button {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #c4c4c4;
}

.ant-carousel .homeCarousel .slick-dots li {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}

.ant-carousel .homeCarousel .slick-dots li.slick-active {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fc9c1c;
}

.ant-carousel .homeCarousel .slick-dots li.slick-active button {
  background: #fc9c1c;
}

.contactForm.ant-form .ant-form-item {
  width: 100%;
}

.contactForm.ant-form .ant-input {
  color: #ffffff;
  opacity: 60%;
  background: transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  border: 0;
  border-bottom: 0.5px solid #ffffff;
  padding: 5px 11px;
}

.contactFormHome.ant-form .ant-input,
.contactFormHome.ant-form label {
  opacity: 1;
  background: transparent;
  color: #ffffff;
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
}

.contactFormHome.ant-form .ant-input {
  border-bottom: 2px solid rgba(188, 193, 198, 1);
}

.contactFormHome.ant-form .ant-form-item-explain-error {
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.03em;
}

.contactForm.ant-form .ant-input-affix-wrapper {
  color: #ffffff;
  background: transparent;
  border: 0;
  border-bottom: 0.5px solid #ffffff;
  padding: 0;
}

.contactForm.ant-form .ant-input-affix-wrapper .ant-input {
  border: 0;
}

.contactForm.ant-form .ant-input-affix-wrapper .ant-input-suffix {
  color: #c4c4c4;
  font-size: 14px;
  line-height: 19px;
}

.contactFormHome.ant-form .ant-input-affix-wrapper .ant-input-suffix {
  color: #c4c4c4;
  font-size: 28px;
  line-height: 34px;
}

.contactForm.ant-form
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #ffffff;
}

.contactForm.ant-form .ant-input-affix-wrapper:focus,
.contactForm.ant-form .ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.contactForm.ant-form
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: transparent;
  border: 0;
  border-bottom: 0.5px solid #ffffff;
  padding: 5px 11px;
  height: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.contactFormHome.ant-form
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: transparent;
  border: 0;
  border-bottom: 0.5px solid #ffffff;
  color: #ffffff;
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
}

.contactForm.ant-form .ant-select-selection-placeholder {
  color: #ffffff;
}

.contactForm.ant-form .ant-select-arrow {
  color: #ffffff;
  margin-top: -12px;
  font-size: 22px;
}

.contactForm.ant-form
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #ffffff;
  box-shadow: none;
}

.contactForm.ant-form
  .ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: transparent;
}

.contactForm.ant-form
  .organizationSelect.ant-form-item-has-error
  .ant-form-item-control-input-content {
  border: 0;
}

.contactForm.ant-form .ant-input:focus {
  box-shadow: none;
}

.contactForm.ant-form .ant-input::placeholder {
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.contactFormHome.ant-form .ant-input::placeholder {
  opacity: 1;
  background: transparent;
  color: rgba(188, 193, 198, 1);
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
}

.contactBtn.ant-btn {
  margin-top: 9px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  background-color: #ee8032;
  padding: 19px 59.5px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #ee8032;
  height: auto;
  width: 50%;
  white-space: break-spaces;
}

.contactBtnHome.ant-btn {
  background: rgba(229, 123, 24, 1);
  padding: 26px 40px;
  border-radius: 16px;
  grid-gap: 10px;
  gap: 10px;
  font-family: Inter, sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  max-width: 200px;
  width: 100%;
  max-height: 86px;
  height: 100%;
  border: none;
  margin-top: 60px;
}

.ant-menu-horizontal > .ant-menu-item:after {
  display: none;
}

.contactBtnHome.ant-btn:hover {
  background: rgba(255, 158, 27, 1);
  color: white;
}

.contactFormHome .ant-form-item {
  max-width: 825px;
  height: 120px;
  grid-gap: 20px;
  grid-gap: 20px;
  gap: 20px;
  color: #ffffff;
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  margin: 0;
  display: flex;
  align-items: flex-end;
}

.contactModal .contactFormHome .ant-form-item {
  max-width: 617px;
  height: 88px;
  grid-gap: 32px;
  grid-gap: 32px;
  gap: 32px;
  color: #ffffff;
  font-family: Inter, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.03em;
  margin: 0;
  display: flex;
  align-items: flex-end;
}

.contactForm.ant-form {
  display: flex;
  flex-direction: column;
}

.contactForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.contactForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper,
.contactForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.contactForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:hover {
  background-color: transparent;
  border-color: transparent;
}

.contactForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.contactForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:focus,
.contactForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
.contactForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper-focused {
  border-color: transparent;
  box-shadow: none;
}

.contactForm.ant-form
  .ant-form-item-has-error
  .ant-form-item-control-input-content {
  border-bottom: 0.5px solid #ff4d4f;
  border-radius: 2px;
}

.contactForm.ant-form
  .inputCheckbox.ant-form-item-has-error
  .ant-form-item-control-input-content {
  border-bottom: 0;
}

@media screen and (max-width: 1500px) {
  .ant-carousel .slick-slider {
    height: 780px;
  }
}

@media screen and (max-width: 1280px) {
  .contactFormHome .ant-form-item {
    max-width: 554px;
    height: 80px;
    font-size: 22px;
    line-height: 28px;
  }

  .ant-carousel .slick-slider {
    height: 650px;
  }

  .contactFormHome.ant-form .ant-input,
  .contactFormHome.ant-form label,
  .contactFormHome.ant-form .ant-input-affix-wrapper .ant-input-suffix,
  .contactFormHome.ant-form
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  .contactFormHome.ant-form .ant-input::placeholder {
    font-size: 22px;
    line-height: 28px;
  }

  .contactBtnHome.ant-btn {
    font-size: 22px;
    line-height: 28px;
    margin-top: 32px;
  }
}

@media screen and (max-width: 1050px) {
  .contactFormHome .ant-form-item {
    max-width: 100%;
  }
}

@media screen and (max-width: 390px) {
  .contactFormHome .ant-form-item {
    max-width: 358px;
  }
}

@media screen and (max-width: 1200px) {
  .ant-carousel .slick-slider {
    height: 650px;
  }
}

@media screen and (max-width: 1023px) {
  .homeHeader.ant-layout-header {
    padding: 20px;
  }

  .ant-carousel .slick-arrow.slick-next {
    right: 20px;
    top: 10%;
  }
  .ant-carousel .slick-slider {
    height: 550px;
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item.headerMenuElementHome {
    height: 60px;
    line-height: 60px;
  }
}

@media screen and (max-width: 800px) {
  .ant-carousel .slick-slider {
    height: 450px;
  }
}

@media screen and (max-width: 600px) {
  .contactForm.ant-form .ant-form-item,
  .contactBtn.ant-btn {
    width: 100%;
  }

  .ant-carousel .homeCarousel .slick-dots-bottom {
    bottom: 0;
  }

  .ant-carousel .slick-arrow.slick-next {
    top: 30%;
  }

  .ant-carousel .slick-slider {
    height: 400px;
  }
}

@media screen and (max-width: 500px) {
  .ant-carousel .slick-slider {
    height: 350px;
  }
}

.footer.ant-layout-footer {
  font-family: Inter, sans-serif;
  padding: 27px 90px;
  font-size: 18px;
  line-height: 22px;
  color: #5a5a5a;
  height: 76px;
  z-index: 5;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.footer.ant-layout-footer {
  background: linear-gradient(90deg, #2e2eb4 0%, #216aad 100%);
}

.footerContent {
  color: #ffffff;
}

@media screen and (max-width: 1320px) {
  .footerContent {
    padding: 0;
  }

  .footer.ant-layout-footer {
    padding: 27px 20px;
  }
}

@media screen and (max-width: 600px) {
  .footer.ant-layout-footer {
    padding: 20px;
  }
}

.AppCookieBanner_cookieBanner__1uf8R {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  border-radius: 30px 30px 0 0;
  padding: 31px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
}

.AppCookieBanner_cookieBannerText__GTEFG {
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

@media only screen and (max-width: 1024px) {
  .AppCookieBanner_cookieBanner__1uf8R {
    flex-direction: column;
    padding: 31px 20px;
  }

  .AppCookieBanner_cookieBannerText__GTEFG {
    margin-bottom: 20px;
  }
}

.acceptBtn.ant-btn {
  border-radius: 10px;
  padding: 20px 60px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  height: auto;
}

.acceptBtn.ant-btn {
  margin-right: 15px;
  background: #fc9c1c;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  color: #ffffff;
}

.acceptBtn.ant-btn:hover {
  border: 1px solid #ffffff;
  color: #000000;
}

.Home_headerContent__4uF_P {
  max-width: 1920px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  align-items: center;
}

.Home_topSectionContainer__2V3aW,
.Home_contactSectionContainer__20rFm,
.Home_whoNeedsSectionContainer__1yvCD,
.Home_whoNeedsSectionContainerButton__30o_J,
.Home_meetLariContainer__2AGTD,
.Home_whoNeedsSectionContainerBlock__cUxle {
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.Home_topSectionContainer__2V3aW {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  justify-content: space-between;
}

.Home_whoNeedsSectionContainer__1yvCD {
  margin-top: 40px;
  grid-gap: 40px;
  gap: 40px;
}

.Home_meetLariContainer__2AGTD {
  justify-content: space-between;
  align-items: start;
  grid-gap: 32px;
  gap: 32px;
  flex-direction: column;
}

.Home_whoNeedsSectionBgIcon__poydi {
  position: absolute;
  right: 5%;
  top: 44%;
  width: 55%;
  transform: rotate(180deg);
}

.Home_whoNeedsSectionContainerButton__30o_J,
.Home_whoNeedsSectionContainerBlock__cUxle {
  grid-gap: 40px;
  gap: 40px;
  z-index: 1;
}

.Home_videoSectionContainer__2P0fU,
.Home_sliderSectionContent__2zreA {
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
}

.Home_logo__2W7AR {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.Home_logoImgMain__2_bT2 {
  width: 112px;
  height: 110px;
}

.Home_logoTitle__hcOEH {
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 60px;
  color: #ffffff;
  margin-left: 10px;
}

.Home_topSection__2QIkb {
  background: linear-gradient(0deg, #216bad 0.69%, #2e2eb4 65.03%);
  padding: 155px 88px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
}

.Home_whatIsLariSection__FrxTi {
  background: linear-gradient(180deg, #216bad 0.69%, #2e2eb4 65.03%);
  height: 100vh;
  position: relative;
}

.Home_whoNeedsSection__2rsc7 {
  height: 100%;
  position: relative;
}

.Home_whoNeedsSectionTop__3XkXF {
  background: linear-gradient(0deg, #216bad 0.69%, #2e2eb4 65.03%);
  padding: 90px 88px;
}

.Home_whoNeedsSectionButton__5cMSN {
  background: linear-gradient(180deg, #216bad 0.69%, #2e2eb4 65.03%);
  padding: 60px 88px 156px 88px;
  grid-gap: 40px;
  gap: 40px;
  display: flex;
  flex-direction: column;
}

.Home_meetLariSection__tmaEa {
  background: linear-gradient(180deg, #216bad 0.69%, #2e2eb4 65.03%);
  padding: 110px 88px;
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
}

.Home_topSectionLeft__IbX6y {
  border: 2px solid rgba(255, 158, 27, 1);
  max-width: 727px;
  max-height: 560px;
  width: 36%;
  height: 560px;
  padding: 40px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Home_topSectionRight__1Gb1P {
  max-width: 980px;
  width: 64%;
  max-height: 560px;
  height: 560px;
}

.Home_topSectionTitle__1AJMj {
  font-size: 42px;
  line-height: 48px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 42px;
}

.Home_topSectionContent__1KRwr {
  font-size: 17px;
  line-height: 22px;
  color: #fc9c1c;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 700;
}

.Home_topSectionSecondContent__12g2v {
  font-size: 17px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 25px;
  text-align: center;
  font-weight: 600;
}

.Home_topSectionLine__2vXuc {
  margin: 0 auto;
  display: block;
}

.Home_topSectionBtns__v7mUL {
  display: flex;
  justify-content: space-between;
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  margin: 0 auto;
  text-align: center;
}

.Home_signUpBtn__1Q8Db,
.Home_signInBtn__681-c {
  width: 160px;
  height: 58px;
  padding: 12px 24px 12px 24px;
  border-radius: 16px;
  grid-gap: 10px;
  gap: 10px;
  text-align: center;
}

.Home_signUpBtnHome__3Z0BD {
  width: 200px;
  height: 86px;
  padding: 26px 40px;
  border-radius: 16px;
  background: rgba(229, 123, 24, 1);
  color: #ffffff;
}

.Home_signUpBtn__1Q8Db {
  background: #e57b18;
  color: #ffffff;
  margin: 0 24px !important;
  border: 2px solid #e57b18;
  text-align: center !important;
}

.Home_signUpBtn__1Q8Db:hover,
.Home_signUpBtnHome__3Z0BD:hover {
  background: #ff9e1b;
  border: 2px solid #ff9e1b;
}

.Home_signInBtn__681-c:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.Home_signInBtn__681-c {
  border: 2px solid rgb(255, 255, 255);
}

.Home_videoSection__1ZSlh {
  padding: 100px;
  background: #ffffff;
}

.Home_videoSectionTitle__Ygfx- {
  font-size: 64px;
  line-height: 85px;
  color: #000000;
}

.Home_videoSectionContent__3A8ip {
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  width: 50%;
  margin-bottom: 80px;
}

.Home_videosWrapper__1hzS_ {
  display: flex;
  justify-content: space-between;
}

.Home_videosWrapperRow__Iw71R {
  width: 49%;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Home_videosWrapperCol__3wR1c {
  height: 49%;
  max-height: 230px;
}

.Home_contactSection__169ZP {
  background: linear-gradient(0deg, #216bad 0.69%, #2e2eb4 65.03%);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  grid-gap: 60px;
  gap: 60px;
  margin-bottom: 76px;
  position: relative;
}

.Home_contactSectionLeft__19gsJ {
  width: 50%;
}

.Home_contactSectionIcon__2KYj3 {
  max-width: 1035px;
  max-height: 900px;
  height: 100%;
  width: 50%;
}

.Home_contactSectionTitle__xy4m2 {
  font-size: 40px;
  line-height: 53px;
  color: #ffffff;
  margin-bottom: 22px;
  font-weight: 400;
}

.Home_whoNeedsSectionTitle__3RSjj {
  font-size: 42px;
  line-height: 48px;
  color: #ffffff;
  margin-bottom: 15px;
  font-weight: 500;
}

.Home_meetLariTitle__3ZAc7 {
  font-size: 42px;
  line-height: 48px;
  color: #ffffff;
  margin-bottom: 23px;
  font-weight: 500;
}

.Home_meetLariOrangeText__be-GJ {
  font-size: 16px;
  line-height: 22px;
  color: #fc9c1c;
  font-weight: 600;
}

.Home_meetLariWhiteText__exDYd {
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  font-weight: 600;
  width: 80%;
}

.Home_meetLariBlockWrapper__3DO0U {
  display: flex;
  justify-content: space-between;
  grid-gap: 32px;
  gap: 32px;
}

.Home_meetLariRightBlockWrapper__2T7Ic {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 80px;
  gap: 80px;
}

.Home_meetLariBgIcon__1_ZC1 {
  position: absolute;
  top: -16%;
  left: 20%;
  width: 64%;
}

.Home_meetLariImageWrapper__2AY4R {
  background: rgba(255, 255, 255, 1);
  width: 156px;
  height: 156px;
  padding: 33px 22px 33px 22px;
  border-radius: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home_whoNeedsSectionOrangeText__1z_mV {
  font-size: 14px;
  line-height: 19px;
  color: #fc9c1c;
  font-weight: 500;
}

.Home_meetLariItemWrapper__2hWzy {
  max-width: 842px;
  width: 100%;
  display: flex;
  grid-gap: 60px;
  gap: 60px;
}

.Home_whoNeedsSectionWhiteText__KD4zw {
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  font-weight: 500;
}

.Home_whoNeedsSectionWhiteTextInBlock__2RGZV {
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 36px;
  max-width: 619px;
  width: 100%;
}

.Home_whoNeedsSectionOrangeTextInBlock__bXI5R {
  font-size: 14px;
  line-height: 19px;
  color: #fc9c1c;
  font-weight: 500;
}

.Home_whoNeedsSectionWhiteTextInBlockSimple__2Rhqv {
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  font-weight: 500;
}

.Home_whoNeedsSectionBlueText__dT4ci {
  font-size: 14px;
  line-height: 19px;
  color: #bae3fe;
  font-weight: 500;
}

.Home_whoNeedsSectionVioletText__1Q3jX {
  font-size: 14px;
  line-height: 19px;
  color: #a5a5e8;
  font-weight: 500;
}

.Home_whoNeedsSectionVioletTitle__3B5D3 {
  background: rgba(165, 165, 232, 1);
  padding: 10px 32px 10px 32px;
  border-radius: 21px 21px 0px 0px;
}

.Home_whoNeedsSectionBlueTitle__VJQfj {
  background: rgba(102, 168, 226, 1);
  padding: 10px 32px 10px 32px;
  border-radius: 21px 21px 0px 0px;
}

.Home_whoNeedsSectionOrangeTitle__2fxDE {
  background: rgba(255, 158, 27, 1);
  padding: 10px 32px 10px 32px;
  border-radius: 21px 21px 0px 0px;
}

.Home_whoNeedsSectionTextBlock__us1iE {
  padding: 24px;
  grid-gap: 8px;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.Home_whoNeedsSectionOrangeWrapper__Z7Mo9 {
  height: 760px;
  border: 2px solid rgba(255, 158, 27, 1);
  border-radius: 24px;
  width: 33.3%;
}

.Home_whoNeedsSectionBlockInfoWrapper__3yakr {
  height: 170px;
  border: 2px solid #ffffff;
  border-radius: 24px;
  width: 33.3%;
  padding: 32px;
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  align-items: center;
}

.Home_whoNeedsSectionTextWrapper__gOFkX {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.Home_whoNeedsSectionImage__1JpAy {
  width: 156px;
  height: 156px;
  background-color: #d9d9d9;
  border-radius: 50%;
}

.Home_whoNeedsSectionBlueWrapper__2SS1T {
  height: 760px;
  border: 2px solid rgba(102, 168, 226, 1);
  border-radius: 24px;
  width: 33.3%;
}

.Home_whoNeedsSectionVioletWrapper__28QBQ {
  height: 760px;
  border: 2px solid rgba(165, 165, 232, 1);
  border-radius: 24px;
  width: 33.3%;
}

.Home_contactSectionContent__27_9n {
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 33px;
}

.Home_contactSectionInfo__2HIVC {
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 20px;
}

.Home_contactSectionInfoThin__3knnR {
  color: rgba(255, 255, 255, 0.5);
}

.Home_contactSectionRight__331Gw {
  max-width: 825px;
  width: 50%;
  padding: 30px 88px 60px 0;
  grid-gap: 24px;
  gap: 24px;
}

.Home_whatIsLariTitle__2-RPn {
  font-family: Exo, sans-serif;
  font-size: 101px;
  font-weight: 700;
  line-height: 121px;
  letter-spacing: -0.03em;
  text-align: center;
  color: #ffffff;
  margin: 50px 0;
}

.Home_titlePaddingTop__137a3 {
  padding-top: 150px;
  opacity: 0;
  animation: Home_moveInAnimationFromTop__XpVKL 1s ease-out 2s forwards;
}

.Home_bgIcon1Animation__2EB88 {
  opacity: 0;
  animation: Home_moveInAnimationFromTop__XpVKL 1s ease-out 0.5s forwards;
}

.Home_whatIsLariSecondSlide__19yIx {
  position: absolute;
  width: 98%;
  left: 1%;
}

.Home_titlePaddingBottom__QaqLZ {
  padding-bottom: 150px;
  opacity: 0;
  animation: Home_moveInAnimation__19Xa2 1s ease-out 0.5s forwards;
}

.Home_bgIcon2Animation__ulE5L {
  opacity: 0;
  animation: Home_moveInAnimation__19Xa2 1s ease-out 1.5s forwards;
}

.Home_whatIsLariTitleSecond__tJpdi {
  font-size: 96px;
  line-height: 116px;
  color: #ffffff;
  margin-bottom: 50px;
  font-weight: 600;
  text-align: center;
  width: 60%;
}

.Home_whatIsLariWhiteText__i5sAE {
  font-size: 80px;
  line-height: 100px;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
}

.Home_hidden__2Q05s {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.5s,
    visibility 0.5s;
}

.Home_whatIsLariOrangeText__2lbx5 {
  font-size: 80px;
  line-height: 100px;
  color: #fc9c1c;
  font-weight: 400;
  text-align: center;
}

.Home_whatIsLariContainer__2--Kq {
  display: flex !important;
  position: relative;
}

.Home_whatIsLariContainerSecondBlock__3UzpH {
  background: rgba(46, 46, 180, 1);
  position: relative;
}

.Home_whatIsLariInfoItem__2ErpC {
  display: flex !important;
  justify-content: center;
  width: 100%;
  grid-gap: 70px;
  gap: 70px;
}

.Home_whatIsLariContainerThirdBlock__35PwX {
  background: radial-gradient(
    79.56% 79.56% at 50% 50%,
    #b2d2f0 0%,
    #478fd2 100%
  );
  padding: 284px 0;
  position: relative;
}

.Home_whatIsLariContainerThirdBlockIcon1__2erTC {
  position: absolute;
  top: 7%;
  left: 4%;
  max-width: 334px;
  max-height: 327px;
  height: 100%;
  width: 100%;
}

.Home_whatIsLariContainerThirdBlockIcon2__28lFK {
  position: absolute;
  bottom: 10%;
  right: 3%;
  transform: rotate(260deg);
  max-width: 334px;
  max-height: 327px;
  height: 100%;
  width: 100%;
}

.Home_whatIsLariThirdBlockText__2RLKD {
  font-size: 96px;
  line-height: 116px;
  color: #2e2eb4;
  font-weight: 400;
  text-align: center;
}

.Home_topSectionBlueLine__3WJBD {
  padding: 29px 0;
  margin: 0 auto;
  max-width: 234px;
  width: 100%;
  z-index: 1;
  position: relative;
}

.Home_whatIsLariBlockItem__2JRqc {
  display: flex;
  flex-direction: column;
}

.Home_whatIsLariInfoBlock__21SMl {
  padding: 174px 0 154px;
  z-index: 1;
}

.Home_whatIsLariBgIcon__1V0SH {
  max-width: 776px;
  width: 45%;
  z-index: 1;
}

.Home_whatIsLariBgIconAnimation__2BFB9 {
  animation: Home_scaleRotate__3vEG9 1.5s cubic-bezier(0.25, 0.1, 0.25, 1) 0s 1 normal
    forwards;
}

/* .textAnimation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-family: Arial, sans-serif;
  width: 100%;
}

.topText {
  font-size: 24px;
  margin-bottom: 10px;
}

@keyframes showAndHideFromTop {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50%);
    opacity: 0;
  }
}

.showAndHideFromTop {
  animation: showAndHideFromTop 10s ease forwards;
}

@keyframes showAndHideFromBottom {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(50%);
    opacity: 0;
  }
}

.showAndHideFromBottom {
  opacity: 0;
  animation: showAndHideFromBottom 10s ease 2s forwards;
} */

.Home_textAnimation__37WNO {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-family: Arial, sans-serif;
  width: 100%;
}

.Home_topText__2higT,
.Home_bottomText__fnT95 {
  display: none;
  opacity: 0;
}
@keyframes Home_showAndHideFromTop__2Lk-0 {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  20% {
    transform: translateY(0);
    opacity: 1;
  }
  80% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50%);
    opacity: 0;
  }
}

@keyframes Home_showAndHideFromBottom__32HN8 {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  20% {
    transform: translateY(0);
    opacity: 1;
  }
  80% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(50%);
    opacity: 0;
  }
}

.Home_showTop__3Ar-I {
  display: block;
  animation: Home_showAndHideFromTop__2Lk-0 13s ease forwards;
}

.Home_showBottom__2D8l_ {
  display: block;
  animation: Home_showAndHideFromBottom__32HN8 13s ease 2s forwards;
}

.Home_bottomText__fnT95 {
  font-size: 18px;
}

@keyframes Home_scaleRotate__3vEG9 {
  0% {
    transform: rotate(-360deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

.Home_whatIsLariFirstSlide__3rveG {
  position: absolute;
  max-width: 100%;
  width: 100%;
  left: 1%;
  top: 17%;
}

.Home_whatIsLariFirstSlide2__3JjcX {
  position: absolute;
  max-width: 60%;
  width: 100%;
  top: -5%;
}

.Home_whatIsLariOrangeTitle__1hbvA {
  color: #fc9c1c;
  font-weight: 400;
}

@keyframes Home_wipeAnimationn__3ehuO {
  from {
    -webkit-clip-path: inset(0 0 100% 0);
            clip-path: inset(0 0 100% 0);
    opacity: 0;
  }
  to {
    opacity: 1;
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0);
  }
}

@keyframes Home_wipeAnimationFromDown__1x6N3 {
  from {
    -webkit-clip-path: inset(100% 0 0 0);
            clip-path: inset(100% 0 0 0);
    opacity: 0;
  }
  to {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0);
    opacity: 1;
  }
}

.Home_whatIsLariLine__3cdeW {
  margin: 0 auto;
}

.Home_whatIsLariLineAnimation__EAGnu {
  opacity: 0;
  animation: Home_wipeAnimationn__3ehuO 1s ease 2s forwards;
}

.Home_whatIsLariLineVerticalDown__1nZff {
  margin: 0 auto;
  margin-top: 50px;
  height: 50%;
}

.Home_whatIsLariLineVerticalDownAnimation__2pLac {
  opacity: 0;
  animation: Home_wipeAnimationFromDown__1x6N3 1s ease 1.5s forwards;
}

.Home_whatIsLariLineVerticalUp__1F6-3 {
  margin: 0 auto;
  margin-bottom: 50px;
  height: 50%;
}

.Home_whatIsLariLineVerticalUpAnimation__1mqcP {
  opacity: 0;
  animation: Home_wipeAnimationn__3ehuO 1s ease 0.5s forwards;
}

.Home_sliderSection__1nNgY {
  padding: 0 100px 100px;
  background: #ffffff;
}

.Home_carouselElement__TvyQw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 120px;
  z-index: 1;
  margin: 0 5px;
}

.Home_elementContent__cQicv {
  position: relative;
  background: linear-gradient(180deg, #216aad 0%, #2e2eb4 100%),
    linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #ffffff, #ffffff), #ffffff;
  border-radius: 30px;
  width: 70%;
}

.Home_elementTitle__2jvAc {
  font-size: 64px;
  line-height: 85px;
  color: #2e2eb4;
  width: 20%;
}

.Home_elementInfo__1Ojfh {
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  padding: 100px;
}

.Home_elementNumber__2QYb7 {
  font-size: 288px;
  line-height: 383px;
  color: rgba(252, 156, 28, 0.5);
  position: absolute;
  left: -12%;
  bottom: 25%;
  z-index: -1;
}

.Home_newFirstTitle__2KQ9D {
  font-family: Exo, sans-serif;
  font-size: 70px;
  font-weight: 400;
  line-height: 84px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.Home_newFirstTitleBlock__GyJM3 {
  font-family: Exo, sans-serif;
  font-size: 70px;
  font-weight: 400;
  line-height: 84px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.Home_newSecondTitle__te66P {
  font-family: Exo, sans-serif;
  font-size: 55px;
  font-weight: 400;
  line-height: 66px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.Home_newThirdTitleBold__wz5X9 {
  font-family: Inter, sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.Home_newThirdTitle__1_HE6 {
  font-family: Inter, sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.Home_newThirdTitleTextContact__3rZsV {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.Home_newFourthTitleBold__3ObeU {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.Home_whiteButton__3-vOP {
  border-bottom: 2px solid #ffffff;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.Home_newFourthTitleItalic__2hcMn {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  font-style: italic;
  margin: 0;
}

.Home_newFourthTitleName__3mcFJ {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.Home_newFourthTitle__2OYMe {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.Home_newMainText__26CH3 {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff !important;
  margin: 0;
}

.Home_newMainTextBold__ZSR31 {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.Home_newFourthTitleBoldName__309u9 {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.Home_newMainTextSmallBold__2nuRs {
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.Home_newMainTextSmall__3OaET {
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.Home_orangeTitle__2LKP4 {
  color: rgba(255, 158, 27, 1);
}

.Home_blueTitle__3Ch6k {
  color: rgba(102, 168, 226, 1);
}

.Home_blueTitleText__1wbA4 {
  color: rgba(46, 46, 180, 1);
  font-weight: 400;
  z-index: 1;
  position: relative;
}

.Home_violetTitle__1bz9l {
  color: rgba(165, 165, 232, 1);
}

.Home_newMainTextWidth__1VBaG {
  max-width: 982px;
  width: 100%;
}

.Home_textAlign__3LTpL {
  text-align: center;
}

.Home_whatIsLariContainerThirdBlockbg__74Saz {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
}

.Home_whatIsLariBgIconForMobile__1kieo {
  display: none !important;
}

@keyframes Home_wipeAnimation__7Kp2L {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.Home_animationForMainTitle__3DnB5 {
  white-space: nowrap;
  overflow: hidden;
  width: 0;
  animation: Home_wipeAnimation__7Kp2L 0.5s steps(100, end) 1.5s forwards;
}

.Home_lineBlueAnimation__2m65T {
  opacity: 0;
  animation: Home_wipeAnimationFromLeft__9bqK8 1s ease 1.5s forwards;
}

@keyframes Home_wipeAnimationFromLeft__9bqK8 {
  from {
    -webkit-clip-path: inset(0 100% 0 0);
            clip-path: inset(0 100% 0 0);
    opacity: 0;
  }
  to {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0);
    opacity: 1;
  }
}

@keyframes Home_moveInAnimation__19Xa2 {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes Home_moveInAnimationFromTop__XpVKL {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes Home_moveInAnimationFromBottom__3X55i {
  0% {
    opacity: 0;
    transform: translateY(1000%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.Home_animationForSecondTitle__2R2qS {
  opacity: 0;
  animation: Home_moveInAnimation__19Xa2 0.5s ease-out 3s forwards;
}

.Home_blueFirstTextAnimation__168Fp {
  opacity: 0;
  animation: Home_moveInAnimationFromBottom__3X55i 1s ease 0.5s forwards;
}

.Home_blueSecondTextAnimation__1xVlU {
  opacity: 0;
  animation: Home_moveInAnimationFromBottom__3X55i 1s ease-out 2s forwards;
}

@media screen and (max-width: 1700px) {
  .Home_topSectionLeft__IbX6y,
  .Home_topSectionRight__1Gb1P {
    height: 510px;
  }

  .Home_topSectionLeft__IbX6y {
    padding: 20px;
  }
}

@media screen and (max-width: 1540px) {
  .Home_topSectionLeft__IbX6y,
  .Home_topSectionRight__1Gb1P {
    height: 450px;
  }
}

@media screen and (max-width: 1500px) {
  .Home_topSection__2QIkb {
    padding: 70px 88px 147px;
  }

  .Home_whoNeedsSectionTop__3XkXF {
    padding: 50px 88px 42px;
  }

  .Home_whatIsLariInfoBlock__21SMl {
    padding: 120px 0;
  }

  .Home_whatIsLariTitle__2-RPn {
    font-size: 89px;
    line-height: 100px;
  }

  .Home_whatIsLariInfoBlock__21SMl {
    padding: 150px 0;
  }

  .Home_whatIsLariContainerThirdBlockIcon1__2erTC,
  .Home_whatIsLariContainerThirdBlockIcon2__28lFK {
    max-width: 270px;
  }

  .Home_whatIsLariContainerThirdBlockIcon1__2erTC {
    top: 3%;
  }

  .Home_whatIsLariContainerThirdBlockIcon2__28lFK {
    bottom: 3%;
  }

  .Home_whatIsLariContainerThirdBlock__35PwX {
    padding: 250px 0;
  }

  .Home_topSectionContainer__2V3aW {
    grid-gap: 30px;
    gap: 30px;
  }

  .Home_signUpBtnHome__3Z0BD {
    width: 200px;
    max-height: 66px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 1400px) {
  .Home_topSectionLeft__IbX6y,
  .Home_topSectionRight__1Gb1P {
    height: 400px;
  }
}

@media screen and (max-width: 1280px) {
  .Home_contactSectionIcon__2KYj3 {
    max-width: 674px;
    max-height: 586px;
  }

  .Home_contactSection__169ZP {
    align-items: flex-end;
  }

  .Home_topSection__2QIkb {
    padding: 11px 88px 65px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .Home_topSectionBtns__v7mUL {
    font-size: 26px;
    line-height: 34px;
  }

  .Home_signUpBtnHome__3Z0BD {
    width: 140px;
    height: 58px;
    padding: 12px 24px;
  }

  .Home_contactSectionRight__331Gw {
    max-width: 554px;
    padding: 40px 83px 96px 0;
  }

  .Home_contactTitle__wXuNs {
    margin-bottom: 32px;
  }

  .Home_whoNeedsSectionTop__3XkXF {
    padding: 40px 88px 46px 88px;
  }

  .Home_whoNeedsSectionContainer__1yvCD {
    grid-gap: 28px;
    gap: 28px;
  }

  .Home_whoNeedsSectionBgIcon__poydi {
    top: 48%;
    width: 55%;
    right: 0;
  }

  .Home_meetLariSection__tmaEa {
    padding: 105px 88px 59px;
  }

  .Home_whatIsLariTitle__2-RPn {
    font-size: 67px;
    line-height: 80px;
  }

  .Home_whatIsLariLine__3cdeW {
    width: 22px;
  }

  .Home_whatIsLariInfoBlock__21SMl {
    padding: 100px 0;
  }

  .Home_whatIsLariBgIcon__1V0SH {
    max-width: 517px;
  }

  .Home_whatIsLariLineVerticalUp__1F6-3 {
    margin-bottom: 30px;
  }

  .Home_whatIsLariLineVerticalDown__1nZff {
    margin-top: 30px;
  }

  .Home_whatIsLariContainerThirdBlock__35PwX {
    padding: 200px 0;
  }

  .Home_whatIsLariContainerThirdBlockIcon1__2erTC,
  .Home_whatIsLariContainerThirdBlockIcon2__28lFK {
    max-width: 220px;
  }

  .Home_topSectionLeft__IbX6y,
  .Home_topSectionRight__1Gb1P {
    height: 320px;
  }

  .Home_topSectionLeft__IbX6y .Home_textAlign__3LTpL {
    font-size: 16px;
    line-height: 20px;
  }

  .Home_signUpBtnHome__3Z0BD {
    font-size: 16px;
    line-height: 20px;
    height: 100%;
  }
}

@media screen and (max-width: 1655px) {
  .Home_whoNeedsSectionOrangeWrapper__Z7Mo9,
  .Home_whoNeedsSectionBlueWrapper__2SS1T,
  .Home_whoNeedsSectionVioletWrapper__28QBQ {
    height: 750px;
  }

  .Home_whoNeedsSectionBlockInfoWrapper__3yakr {
    padding: 24px;
  }

  .Home_whoNeedsSectionImage__1JpAy {
    width: 112px;
    height: 112px;
  }
}
@media screen and (max-width: 1540px) {
  .Home_whoNeedsSectionOrangeWrapper__Z7Mo9,
  .Home_whoNeedsSectionBlueWrapper__2SS1T,
  .Home_whoNeedsSectionVioletWrapper__28QBQ {
    height: 550px;
  }

  .Home_whoNeedsSectionBgIcon__poydi {
    top: 42%;
    width: 56%;
  }

  .Home_meetLariSection__tmaEa {
    padding: 30px 88px 60px;
  }
}

@media screen and (max-width: 1410px) {
  .Home_whoNeedsSectionOrangeWrapper__Z7Mo9,
  .Home_whoNeedsSectionBlueWrapper__2SS1T,
  .Home_whoNeedsSectionVioletWrapper__28QBQ {
    height: 630px;
  }
}

@media screen and (max-width: 1390px) {
  .Home_whoNeedsSectionBlockInfoWrapper__3yakr {
    flex-direction: column;
  }

  .Home_whoNeedsSectionBlockInfoWrapper__3yakr {
    height: 290px;
  }
}

@media screen and (max-width: 1310px) {
  .Home_whoNeedsSectionOrangeWrapper__Z7Mo9,
  .Home_whoNeedsSectionBlueWrapper__2SS1T,
  .Home_whoNeedsSectionVioletWrapper__28QBQ {
    height: 730px;
  }
}

@media screen and (max-width: 1023px) {
  .Home_whoNeedsSectionOrangeWrapper__Z7Mo9,
  .Home_whoNeedsSectionBlueWrapper__2SS1T,
  .Home_whoNeedsSectionVioletWrapper__28QBQ {
    width: 100%;
    height: 390px;
  }

  .Home_whoNeedsSectionContainer__1yvCD {
    grid-gap: 26px;
    gap: 26px;
  }

  .Home_whoNeedsSectionTop__3XkXF {
    padding: 20px 16px 20px 16px;
  }

  .Home_whoNeedsSectionButton__5cMSN {
    padding: 14px 16px 37px 16px;
  }

  .Home_contactSectionIcon__2KYj3 {
    display: none;
  }

  .Home_contactSectionRight__331Gw {
    padding: 40px 16px 78px;
    width: 100%;
    max-width: 100%;
  }

  .Home_whoNeedsSectionBgIcon__poydi {
    top: 64%;
    width: 54%;
    transform: rotate(18deg);
  }

  .Home_meetLariImageWrapper__2AY4R {
    width: 116px;
    height: 116px;
  }

  .Home_meetLariImage__dvwaD {
    width: 85px;
    height: 85px;
  }

  .Home_meetLariBgIcon__1_ZC1 {
    width: 80%;
  }

  .Home_meetLariSection__tmaEa {
    padding: 70px 16px 53px;
    grid-gap: 20px;
    gap: 20px;
  }

  .Home_meetLariBlockWrapper__3DO0U,
  .Home_meetLariRightBlockWrapper__2T7Ic {
    grid-gap: 24px;
    gap: 24px;
  }

  .Home_topSection__2QIkb {
    padding: 24px 16px 49px;
  }

  .Home_topSectionLeft__IbX6y {
    max-width: 100%;
  }

  .Home_topSectionContainer__2V3aW {
    grid-gap: 40px;
    gap: 40px;
  }

  .Home_whatIsLariContainerThirdBlock__35PwX {
    padding: 170px 0;
  }

  .Home_whatIsLariContainerThirdBlockIcon1__2erTC,
  .Home_whatIsLariContainerThirdBlockIcon2__28lFK {
    max-width: 200px;
  }

  .Home_meetLariBlockWrapper__3DO0U {
    flex-direction: column;
  }
}

@media screen and (max-width: 850px) {
  .Home_whoNeedsSectionContainerBlock__cUxle {
    flex-direction: column;
    grid-gap: 26px;
    gap: 26px;
  }

  .Home_whoNeedsSectionBgIcon__poydi {
    top: 43%;
    width: 177%;
  }

  .Home_whoNeedsSectionBlockInfoWrapper__3yakr {
    width: 100%;
  }

  .Home_meetLariImageWrapper__2AY4R {
    width: 86px;
    height: 86px;
  }

  .Home_meetLariImage__dvwaD {
    width: 63px;
    height: 63px;
  }

  .Home_meetLariBgIcon__1_ZC1 {
    width: 80%;
  }

  .Home_meetLariContainer__2AGTD {
    flex-direction: column;
    grid-gap: 40px;
    gap: 40px;
  }

  .Home_meetLariBgIcon__1_ZC1 {
    width: 180%;
    left: 0;
  }

  .Home_whatIsLariInfoBlock__21SMl {
    padding: 80px 0;
  }

  .Home_whatIsLariContainerThirdBlock__35PwX {
    padding: 150px 0;
  }

  .Home_whatIsLariContainerThirdBlockIcon1__2erTC,
  .Home_whatIsLariContainerThirdBlockIcon2__28lFK {
    max-width: 180px;
  }

  .Home_whatIsLariContainerThirdBlockIcon1__2erTC {
    top: 1%;
    max-height: 230px;
  }

  .Home_whatIsLariContainerThirdBlockIcon2__28lFK {
    bottom: 1%;
    max-height: 230px;
  }
}

@media screen and (max-width: 390px) {
  .Home_contactSectionRight__331Gw {
    padding: 40px 16px 78px;
  }
}

@media screen and (max-width: 1430px) {
  .Home_whatIsLariInfoBlock__21SMl {
    width: 72%;
  }
}

@media screen and (max-width: 1350px) {
  .Home_whatIsLariTitle__2-RPn {
    font-size: 100px;
    line-height: 113px;
  }

  .Home_whatIsLariWhiteText__i5sAE,
  .Home_whatIsLariOrangeText__2lbx5 {
    font-size: 70px;
    line-height: 100px;
  }
}

@media screen and (max-width: 1315px) {
  .Home_whatIsLariTitle__2-RPn {
    font-size: 95px;
    line-height: 113px;
  }

  .Home_whatIsLariWhiteText__i5sAE,
  .Home_whatIsLariOrangeText__2lbx5 {
    font-size: 65px;
    line-height: 100px;
  }
}

@media screen and (max-width: 1315px) {
  .Home_whatIsLariTitle__2-RPn {
    font-size: 89px;
    line-height: 100px;
  }

  .Home_whatIsLariWhiteText__i5sAE,
  .Home_whatIsLariOrangeText__2lbx5 {
    font-size: 60px;
    line-height: 80px;
  }

  .Home_whatIsLariInfoBlock__21SMl {
    width: 64%;
  }

  .Home_whatIsLariThirdBlockText__2RLKD {
    font-size: 90px;
    line-height: 110px;
  }
}

@media screen and (max-width: 1200px) {
  .Home_signInBtn__681-c,
  .Home_signUpBtn__1Q8Db {
    width: 100%;
    margin-bottom: 20px;
  }

  .Home_signUpBtn__1Q8Db {
    padding: 7px 20px;
    height: 100%;
  }

  .Home_topSection__2QIkb {
    align-items: normal;
  }

  .Home_whoNeedsSectionOrangeText__1z_mV,
  .Home_whoNeedsSectionWhiteText__KD4zw,
  .Home_whoNeedsSectionBlueText__dT4ci,
  .Home_whoNeedsSectionVioletText__1Q3jX {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
  }

  .Home_whatIsLariTitle__2-RPn {
    font-size: 75px;
    line-height: 90px;
  }

  .Home_whatIsLariWhiteText__i5sAE,
  .Home_whatIsLariOrangeText__2lbx5 {
    font-size: 45px;
    line-height: 65px;
  }

  .Home_whatIsLariBgIcon__1V0SH {
    max-width: 500px;
  }

  .Home_whatIsLariTitleSecond__tJpdi {
    font-size: 80px;
    line-height: 90px;
  }
  .Home_whatIsLariLineVerticalDown__1nZff,
  .Home_whatIsLariLineVerticalUp__1F6-3 {
    width: 8%;
  }

  .Home_whatIsLariThirdBlockText__2RLKD {
    font-size: 70px;
    line-height: 60px;
  }
}

@media screen and (max-width: 1023px) {
  .Home_signUpBtn__1Q8Db {
    margin: 0 !important;
  }
  .Home_sliderSection__1nNgY {
    padding: 0 20px 100px;
  }

  .Home_carouselElement__TvyQw {
    padding-top: 0;
    flex-direction: column;
    align-items: normal;
  }

  .Home_elementTitle__2jvAc {
    width: 75%;
    margin-bottom: 110px;
  }

  .Home_elementContent__cQicv {
    width: 100%;
  }

  .Home_elementInfo__1Ojfh {
    padding: 30px;
  }

  .Home_elementNumber__2QYb7 {
    font-size: 144px;
    line-height: 191px;
    left: auto;
    right: 0;
    bottom: auto;
    top: -60%;
  }

  .Home_contactSectionIcon__2KYj3 {
    width: 65.5%;
  }

  .Home_topSectionContainer__2V3aW {
    flex-direction: column;
  }

  .Home_topSectionLeft__IbX6y {
    width: 100%;
  }

  .Home_whoNeedsSectionContainer__1yvCD,
  .Home_whoNeedsSectionContainerButton__30o_J {
    flex-direction: column;
  }

  .Home_whatIsLariTitle__2-RPn {
    font-size: 57px;
    line-height: 80px;
  }

  .Home_whatIsLariWhiteText__i5sAE,
  .Home_whatIsLariOrangeText__2lbx5 {
    font-size: 40px;
    line-height: 60px;
  }

  .Home_whatIsLariBgIcon__1V0SH {
    max-width: 450px;
  }

  .Home_whatIsLariTitleSecond__tJpdi {
    font-size: 70px;
    line-height: 80px;
  }
  .Home_whatIsLariLineVerticalDown__1nZff,
  .Home_whatIsLariLineVerticalUp__1F6-3 {
    width: 10%;
  }

  .Home_whatIsLariThirdBlockText__2RLKD {
    font-size: 50px;
    line-height: 35px;
  }

  .Home_whatIsLariContainerThirdBlockIcon2__28lFK,
  .Home_whatIsLariContainerThirdBlockIcon1__2erTC {
    width: 25%;
  }

  .Home_topSectionRight__1Gb1P {
    height: 100%;
    max-height: 560px;
    width: 100%;
  }

  .Home_topSectionLeft__IbX6y {
    width: 100%;
    height: 100%;
    max-height: 560px;
    grid-gap: 20px;
    gap: 20px;
  }

  .Home_topSectionLeft__IbX6y .Home_textAlign__3LTpL,
  .Home_signUpBtnHome__3Z0BD {
    font-size: 22px;
    line-height: 28px;
  }
}

@media screen and (max-width: 800px) {
  .Home_topSection__2QIkb {
    padding: 20px;
    flex-direction: column;
    width: 100%;
  }

  .Home_meetLariContainer__2AGTD {
    flex-direction: column;
  }

  .Home_topSectionLeft__IbX6y,
  .Home_topSectionRight__1Gb1P {
    width: 100%;
  }

  .Home_topSectionContent__1KRwr {
    margin-bottom: 25px;
  }

  .Home_topSectionBtns__v7mUL {
    margin-top: 20px;
  }

  .Home_contactSectionIcon__2KYj3 {
    width: 87%;
  }

  .Home_whatIsLariTitle__2-RPn {
    font-size: 40px;
    line-height: 60px;
  }

  .Home_whatIsLariWhiteText__i5sAE,
  .Home_whatIsLariOrangeText__2lbx5 {
    font-size: 30px;
    line-height: 40px;
  }

  .Home_whatIsLariBgIcon__1V0SH {
    max-width: 400px;
  }

  .Home_whatIsLariTitleSecond__tJpdi {
    font-size: 60px;
    line-height: 70px;
  }
  .Home_whatIsLariLineVerticalDown__1nZff,
  .Home_whatIsLariLineVerticalUp__1F6-3 {
    width: 10%;
  }

  .Home_whatIsLariThirdBlockText__2RLKD {
    font-size: 40px;
    line-height: 40px;
  }

  .Home_whatIsLariContainerThirdBlockIcon2__28lFK,
  .Home_whatIsLariContainerThirdBlockIcon1__2erTC {
    width: 25%;
  }
  .Home_whatIsLariContainerThirdBlock__35PwX {
    padding: 140px;
  }

  .Home_topSectionBlueLine__3WJBD {
    padding: 10px 0;
  }
}

@media screen and (max-width: 670px) {
  .Home_whoNeedsSectionOrangeWrapper__Z7Mo9,
  .Home_whoNeedsSectionBlueWrapper__2SS1T,
  .Home_whoNeedsSectionVioletWrapper__28QBQ {
    height: 365px;
  }

  .Home_whatIsLariBgIcon__1V0SH {
    display: none;
    max-width: 0;
  }

  .Home_whatIsLariBgIconForMobile__1kieo {
    display: block !important;
    position: relative;
    left: 0;
  }
  .Home_whatIsLariInfoBlock__21SMl {
    position: relative;
    right: 10%;
    padding: 50px 0;
  }
}

@media screen and (max-width: 600px) {
  .Home_topSection__2QIkb {
    padding: 20px;
    flex-direction: column;
    width: 100%;
  }

  .Home_topSectionLeft__IbX6y,
  .Home_topSectionRight__1Gb1P {
    width: 100%;
  }

  .Home_topSectionContent__1KRwr {
    margin-bottom: 25px;
  }

  .Home_videoSection__1ZSlh {
    padding: 20px;
  }

  .Home_videoSectionContent__3A8ip {
    width: 100%;
    margin-bottom: 40px;
  }

  .Home_videosWrapper__1hzS_ {
    flex-direction: column;
  }

  .Home_videosWrapperRow__Iw71R {
    width: 100%;
  }

  .Home_videosWrapperCol__3wR1c {
    height: auto;
    max-height: none;
  }

  .Home_carouselElement__TvyQw {
    padding-top: 120px;
    flex-direction: column;
    align-items: normal;
  }

  .Home_contactSectionIcon__2KYj3 {
    width: 87%;
  }

  .Home_topSectionContainer__2V3aW,
  .Home_contactSectionContainer__20rFm {
    flex-direction: column;
  }

  .Home_whatIsLariTitle__2-RPn {
    font-size: 37px;
    line-height: 45px;
  }

  .Home_whatIsLariWhiteText__i5sAE,
  .Home_whatIsLariOrangeText__2lbx5 {
    font-size: 20px;
    line-height: 30px;
  }

  .Home_whatIsLariTitleSecond__tJpdi {
    font-size: 40px;
    line-height: 50px;
  }

  .Home_whatIsLariThirdBlockText__2RLKD {
    font-size: 30px;
    line-height: 30px;
  }
}

@media screen and (max-width: 500px) {
  .Home_carouselElement__TvyQw {
    padding-top: 120px;
    flex-direction: column;
    align-items: normal;
  }

  .Home_elementNumber__2QYb7 {
    top: -25%;
  }

  .Home_contactSectionIcon__2KYj3 {
    width: 100%;
  }

  .Home_whatIsLariTitle__2-RPn {
    font-size: 25px;
    line-height: 45px;
  }

  .Home_whatIsLariWhiteText__i5sAE,
  .Home_whatIsLariOrangeText__2lbx5 {
    font-size: 20px;
    line-height: 30px;
  }

  .Home_whatIsLariTitleSecond__tJpdi {
    font-size: 30px;
    line-height: 40px;
  }

  .Home_whatIsLariThirdBlockText__2RLKD {
    font-size: 30px;
    line-height: 30px;
  }

  .Home_whatIsLariContainerThirdBlock__35PwX {
    padding: 110px 0;
  }

  .Home_whatIsLariContainerThirdBlockIcon1__2erTC {
    top: 1%;
    max-height: 150px;
  }

  .Home_whatIsLariContainerThirdBlockIcon2__28lFK {
    bottom: 1%;
    max-height: 150px;
  }
}

@media screen and (max-width: 1540px) {
  .Home_newFirstTitle__2KQ9D {
    font-size: 54px;
    line-height: 64.99px;
  }

  .Home_newFirstTitleBlock__GyJM3 {
    font-size: 50px;
    line-height: 60px;
  }

  .Home_newSecondTitle__te66P {
    font-size: 42px;
    line-height: 52px;
  }

  .Home_newThirdTitleBold__wz5X9 {
    font-size: 24px;
    line-height: 34.04px;
  }

  .Home_newThirdTitle__1_HE6 {
    font-size: 24px;
    line-height: 34.04px;
  }

  .Home_newThirdTitleTextContact__3rZsV {
    font-size: 20px;
    line-height: 26px;
  }

  .Home_newFourthTitleBold__3ObeU {
    font-size: 20px;
    line-height: 26px;
  }

  .Home_newFourthTitleName__3mcFJ {
    font-size: 20px;
    line-height: 26px;
  }

  .Home_newFourthTitleBoldName__309u9 {
    font-size: 20px;
    line-height: 26px;
  }

  .Home_newFourthTitleItalic__2hcMn {
    font-size: 20px;
    line-height: 26px;
  }

  .Home_newFourthTitle__2OYMe {
    font-size: 20px;
    line-height: 26px;
  }

  .Home_newMainTextBold__ZSR31 {
    font-size: 20px;
    line-height: 26px;
  }

  .Home_newMainText__26CH3 {
    font-size: 20px;
    line-height: 26px;
  }

  .Home_newMainTextSmallBold__2nuRs {
    font-size: 20px;
    line-height: 26px;
  }

  .Home_newMainTextSmall__3OaET {
    font-size: 20px;
    line-height: 26px;
  }
}

@media screen and (max-width: 670px) {
  .Home_newFirstTitle__2KQ9D {
    font-size: 38px;
    line-height: 44px;
  }

  .Home_newFirstTitleBlock__GyJM3 {
    font-size: 38px;
    line-height: 44px;
  }

  .Home_newSecondTitle__te66P {
    font-size: 30px;
    line-height: 36px;
  }

  .Home_newThirdTitleBold__wz5X9 {
    font-size: 16px;
    line-height: 24px;
  }

  .Home_newThirdTitle__1_HE6 {
    font-size: 16px;
    line-height: 24px;
  }

  .Home_newThirdTitleTextContact__3rZsV {
    font-size: 24px;
    line-height: 34px;
  }

  .Home_newFourthTitleBold__3ObeU {
    font-size: 15px;
    line-height: 18.04px;
  }

  .Home_newFourthTitleItalic__2hcMn {
    font-size: 15px;
    line-height: 18.04px;
  }

  .Home_newFourthTitle__2OYMe {
    font-size: 15px;
    line-height: 18.04px;
  }

  .Home_newMainTextBold__ZSR31 {
    font-size: 14px;
    line-height: 19px;
  }

  .Home_newMainText__26CH3 {
    font-size: 14px;
    line-height: 19px;
  }

  .Home_newMainTextSmallBold__2nuRs {
    font-size: 13px;
    line-height: 18px;
  }

  .Home_newMainTextSmall__3OaET {
    font-size: 13px;
    line-height: 18px;
  }
}

.AppVideo_videoContainer__1r8XO {
  width: 100%;
  height: 560px;
  object-fit: cover;
  max-height: 560px;
}

.AppVideo_videoPlayButton__22l9O {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.AppVideo_playButton__1812D {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.AppVideo_playIcon__3JZfS,
.AppVideo_pauseIcon__2391U,
.AppVideo_volumeIcon__3JyPn,
.AppVideo_fullScreenIcon__2Ujl3,
.AppVideo_muteIcon__brsJ1 {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.AppVideo_controlsContainer__3DGzO {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: transparent;
  margin-top: 0;
  padding: 0 25px;
  position: absolute;
  bottom: 15px;
  height: 30px;
}

.AppVideo_timeControls__O_FGm {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
}

.AppVideo_duration__1uuPB {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.AppVideo_volumeControls__3miHd {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20%;
}

.AppVideo_videoContainer__1r8XO:not(root):-webkit-full-screen {
  object-fit: contain;
}

.AppVideo_videoContainer__1r8XO:not(root):fullscreen {
  object-fit: contain;
}

.AppVideo_fullScreen__3eRZC {
  width: 40px;
  display: block;
  cursor: pointer;
  text-align: center;
}

.AppVideo_muteBtn__2NfTz {
  width: 40px;
  display: block;
  cursor: pointer;
  text-align: center;
}

.AppVideo_controls__G-tW6 {
  width: 40px;
  display: block;
  cursor: pointer;
  text-align: center;
}

.AppVideo_videoContainer__1r8XO::-webkit-media-controls {
  display: none;
}

.AppVideo_videoContainer__1r8XO::-webkit-media-controls-enclosure {
  display: none;
}

@media screen and (max-width: 1700px) {
  .AppVideo_videoContainer__1r8XO {
    height: 510px;
  }
  .AppVideo_videosBlockKnowledgeBank__2mCGJ {
    max-height: 785px;
    height: 100%;
  }
}

@media screen and (max-width: 1540px) {
  .AppVideo_videoContainer__1r8XO {
    height: 450px;
  }
  .AppVideo_videosBlockKnowledgeBank__2mCGJ {
    max-height: 785px;
    height: 100%;
  }
}

@media screen and (max-width: 1400px) {
  .AppVideo_controlsContainer__3DGzO {
    padding: 0 50px;
  }

  .AppVideo_videoContainer__1r8XO {
    height: 400px;
  }
  .AppVideo_videosBlockKnowledgeBank__2mCGJ {
    max-height: 785px;
    height: 100%;
  }
}

@media screen and (max-width: 1280px) {
  .AppVideo_videoContainer__1r8XO {
    height: 320px;
  }
  .AppVideo_videosBlockKnowledgeBank__2mCGJ {
    max-height: 785px;
    height: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .AppVideo_timeControls__O_FGm {
    width: 50%;
  }

  .AppVideo_volumeControls__3miHd {
    width: 30%;
  }
}

@media screen and (max-width: 1023px) {
  .AppVideo_videoContainer__1r8XO {
    height: 100%;
    max-height: 560px;
  }
}

@media screen and (max-width: 600px) {
  .AppVideo_playButton__1812D {
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 420px) {
  .AppVideo_timeControls__O_FGm {
    width: 40%;
  }

  .AppVideo_volumeControls__3miHd {
    width: 35%;
  }
}

.app {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 500px;
  overflow: hidden;
  position: relative;
}

.video {
  width: 500px;
  height: 500px;
}

.videoWrapper {
  width: 100%;
  height: 560px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  max-height: 560px;
}

.videosBlockKnowledgeBank {
  max-height: 785px;
  height: 100%;
}

.durationControl,
.volumeControl {
  border-radius: 4px;
  -webkit-appearance: none;
  height: 12px;
  outline: none;
  background: rgba(255, 255, 255, 0.3);
}

.durationControl {
  width: 90%;
  margin-right: 20px;
}

.volumeControl {
  width: 90%;
  margin-left: 10px;
}

.durationControl,
.volumeControl,
.durationControl::-webkit-slider-runnable-track,
.volumeControl::-webkit-slider-runnable-track,
.durationControl::-webkit-slider-thumb,
.volumeControl::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.durationControl::-webkit-slider-runnable-track,
.volumeControl::-webkit-slider-runnable-track {
  height: 12px;
  background: linear-gradient(to right, #fc9c1c, #fc9c1c),
    rgba(255, 255, 255, 0.3);
  background-size: var(--background-size, 0%) 100%;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.durationControl::-webkit-slider-thumb,
.volumeControl::-webkit-slider-thumb {
  width: 18px;
  height: 18px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 50%;
  margin-top: -3px;
}

/** FF*/

.durationControl::-moz-range-progress,
.volumeControl::-moz-range-progress {
  background-color: #fc9c1c;
  border-radius: 5px;
  height: 12px;
}

.durationControl::-moz-range-track,
.volumeControl::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  height: 12px;
}

.durationControl::-moz-range-thumb,
.volumeControl::-moz-range-thumb {
  width: 18px;
  height: 18px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 50%;
  margin-top: -6px;
  border: none;
}

@media screen and (max-width: 1700px) {
  .videoWrapper {
    height: 510px;
  }

  .videosBlockKnowledgeBank {
    max-height: 785px;
    height: 100%;
  }
}

@media screen and (max-width: 1540px) {
  .videoWrapper {
    height: 450px;
  }

  .videosBlockKnowledgeBank {
    max-height: 785px;
    height: 100%;
  }
}

@media screen and (max-width: 1400px) {
  .videoWrapper {
    height: 400px;
  }

  .videosBlockKnowledgeBank {
    max-height: 785px;
    height: 100%;
  }
}

@media screen and (max-width: 1280px) {
  .videoWrapper {
    height: 320px;
  }
  .videosBlockKnowledgeBank {
    max-height: 785px;
    height: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .videoWrapper {
    height: 100%;
    max-height: 560px;
  }
}

@media screen and (max-width: 600px) {
  .videoWrapper {
    margin-bottom: 60px;
  }
}

.contactModal-header {
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  padding: 40px 40px 36px;
  border-bottom: 2px solid rgba(188, 193, 198, 1);
}

.contactModal-header-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.contactModal-header-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contactModal-header-name {
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(255, 158, 27, 1);
  margin: 0;
}

.contactModal-header-title {
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  margin: 0;
}

.contactModal-block {
  padding: 36px 40px 32px;
}

.contactModal-block-title {
  font-family: Exo, sans-serif;
  font-size: 55px;
  font-weight: 400;
  line-height: 66px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 24px;
}

.contactModal .contactForm.ant-form {
  grid-gap: 32px;
  gap: 32px;
}

.contactModal textarea.ant-input {
  width: 617px;
  height: 120px;
  padding: 18px 16px !important;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 1) !important;
}

.contactModal-btnBlock {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.contactModal-btnOrange.ant-btn {
  background: rgba(229, 123, 24, 1);
  max-width: 293px;
  max-height: 58px;
  width: 100%;
  height: 100%;
  padding: 12px 24px;
  border-radius: 16px;
  grid-gap: 10px;
  gap: 10px;
  margin: 0;
  text-align: center;
  font-family: Inter;
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
  color: rgba(255, 255, 255, 1);
}

.contactModal-btnOrange.ant-btn:hover {
  color: rgba(46, 46, 180, 1);
  border: 0.5px solid rgba(46, 46, 180, 1);
}

.contactModal-btnWhite.ant-btn {
  max-width: 293px;
  max-height: 58px;
  width: 100%;
  height: 100%;
  padding: 12px 24px;
  border-radius: 16px;
  grid-gap: 10px;
  gap: 10px;
  margin: 0;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  color: rgba(46, 46, 180, 1);
  font-family: Inter, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.03em;
}

.contactModal-btnWhite.ant-btn:hover {
  color: rgba(229, 123, 24, 1);
  border: 0.5px solid rgba(229, 123, 24, 1);
}

@media only screen and (max-width: 1500px) {
  .contactModal .contactFormHome .ant-form-item {
    height: 60px;
  }

  .contactModal .contactModal-textarea.ant-form-item {
    height: 100px;
  }
}

.AppHeader_headerContent__17YwZ {
  max-width: 1900px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  align-items: center;
  padding: 0 44px;
}

.AppHeader_logo__1c1lC {
  padding: 5px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.AppHeader_logoImg__1veSW {
  width: 200px;
  height: 67px;
}

.AppHeader_logoTitle__GLeiD {
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 60px;
  color: #ffffff;
  margin-left: 10px;
}

.AppHeader_logoTitleBlue__3jljM {
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 60px;
  color: #2e2eb4;
  margin-left: 10px;
}

.AppHeader_searchBtn__2z3MC {
  display: flex;
  background-color: rgba(255, 255, 255, 0.2);
  width: 52px;
  height: 52px;
  padding: 14px;
  border-radius: 300px;
  grid-gap: 10px;
  gap: 10px;
}

.AppHeader_headerSearch__31RLS {
  position: absolute;
  top: -25px;
  right: -10px;
  width: 611px;
  z-index: 1;
}

.AppHeader_searchCloseBtn__BqyNO {
  position: absolute;
  right: 10px;
  cursor: pointer;
  z-index: 1;
}

.AppHeader_searchResults__2pjhZ {
  background: linear-gradient(90.14deg, #2e2eb4 0.03%, #2165ad 99.78%);
}

.AppHeader_searchResultsItem__LXoJu {
  border-top: 0.5px solid #ffffff;
  border-bottom: 0.5px solid #ffffff;
}

.AppHeader_searchResultsItem__LXoJu:nth-child(2n) {
  border-top: none;
}

.AppHeader_logoutLink__blJ4z {
  font-family: Exo, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}

@media only screen and (max-width: 1320px) {
  .AppHeader_headerSearch__31RLS {
    right: 110px;
    width: 600px;
  }

  .AppHeader_headerContent__17YwZ {
    padding: 0;
  }
}

.header.ant-layout-header,
.headerTransparent.ant-layout-header {
  display: flex;
  justify-content: space-between;
  /*padding: 20px 100px;*/
  padding: 0;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  align-items: center;
}

.header.ant-layout-header {
  background: linear-gradient(90deg, #2e2eb4 0%, #216aad 100%);
}

.additionStylesOnHomePage {
  padding: 22px 38px 18px;
  max-width: 1900px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  align-items: center;
}

.simpleHeader.ant-layout-header {
  background: linear-gradient(90deg, #2e2eb4 0%, #216aad 100%);
}

.headerWithLine.ant-layout-header {
  border-bottom: 1px solid #ffffff;
  height: 120px;
}

.logoAdd {
  cursor: pointer;
  max-width: 310px;
  width: 100%;
}

.logoAdd .logoTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 85px;
  color: #ffffff;
  margin-left: 9px;
}

.header.ant-layout-header .searchIcon {
  color: #ffffff;
}

.headerTransparent.ant-layout-header {
  background: transparent;
}

.headerTransparent.ant-layout-header .searchIcon {
  color: #2e2eb4;
}

.headerMenu.ant-menu {
  background: transparent;
  border-bottom: none;
  justify-content: flex-end;
  align-items: center;
}

.headerMenuHome.ant-menu {
  background: transparent;
  border-bottom: none;
}

.headerMenuHome.ant-menu-inline
  .headerSubmenu.ant-menu-submenu
  .ant-menu-title-content::after {
  display: none;
}

.headerTransparent.ant-layout-header
  .headerMenu.ant-menu
  .headerMenuElement.ant-menu-item
  .headerMenuElementLink {
  color: #2e2eb4;
}

.headerMenu.ant-menu .headerMenuElement.ant-menu-item,
.headerMenu.ant-menu .headerSubmenu.ant-menu-submenu {
  display: flex;
  align-items: center;
}

.headerMenuElementHome.ant-menu-item {
  margin-right: 32px;
  padding: 0 !important;
}

.headerMenuElementHome.ant-menu-item:hover::after,
.headerSubmenu.ant-menu-submenu:hover::after {
  display: none;
}

.headerMenuElementHome.ant-menu-item:last-child,
.headerMenuElementHome.ant-menu-item:nth-last-child(4),
.headerMenuElementHome.ant-menu-item:nth-last-child(3),
.headerMenuElementHome.ant-menu-item:nth-last-child(2) {
  margin-right: 0;
}

.headerMenu.ant-menu .headerMenuElement.ant-menu-item.signInBtn {
  padding: 0 0 0 29px;
}

.headerMenu.ant-menu .headerMenuElement.ant-menu-item.signUpBtn {
  padding: 0 29px;
}

.headerMenu.ant-menu .headerSubmenu.ant-menu-submenu {
  padding: 0;
}

.ant-menu-submenu-popup > .ant-menu {
  background: linear-gradient(180deg, #2168ad 0%, #2e2eb4 100%);
}

.ant-menu-submenu-popup > .ant-menu .ant-menu-title-content {
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 35px;
  color: #ffffff;
}

.headerMenu.ant-menu .headerMenuElement.ant-menu-item:hover,
.headerMenu.ant-menu .headerMenuElement.ant-menu-item-active {
  background-color: rgba(63, 63, 239, 1);
}

.headerMenu.ant-menu .headerMenuElement.searchMenuElement:hover {
  background-color: transparent;
}

.ant-menu-horizontal::after {
  display: none !important;
}

.headerMenu.ant-menu .headerMenuElement.signUpBtn.ant-menu-item:hover,
.headerMenu.ant-menu .headerMenuElement.signUpBtn.ant-menu-item-active {
  background-color: rgba(0, 0, 0, 0);
}

.headerMenu.ant-menu .headerMenuElement.signInBtn.ant-menu-item:hover,
.headerMenu.ant-menu .headerMenuElement.signInBtn.ant-menu-item-active {
  background-color: rgba(0, 0, 0, 0);
}

.headerMenuElement.ant-menu-item .headerMenuElementLink {
  color: rgba(255, 255, 255, 1);
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
}

.headerMenuElement.ant-menu-item .homeLinkElement {
  font-weight: 400;
  line-height: 24px;
  font-size: 18px;
  padding: 0 19px;
}

.headerMenuElement.ant-menu-item .signUpBtn {
  padding: 5px 11px;
  text-align: center;
  border-radius: 10px;
  background: #fc9c1c;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  color: #ffffff;
}

.headerMenuElement.ant-menu-item .signInBtn {
  padding: 5px 11px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.08));
  color: #ffffff;
}

.headerMenuElement.ant-menu-item .headerMenuElementLink:hover {
  color: #ffffff;
}

.headerMenuElement.ant-menu-item .signUpBtn:hover {
  color: #2e2eb4;
}

.headerMenuElement.ant-menu-item .signInBtn:hover {
  color: #fc9c1c;
  border: 1px solid #fc9c1c;
}

.headerMenuElement.ant-menu-item:hover::after,
.headerSubmenu.ant-menu-submenu:hover::after {
  display: none;
}

.headerMenu.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item:hover::after,
.headerMenu.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-submenu:hover::after,
.headerMenu.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-active::after,
.headerMenu.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-submenu-active::after,
.headerMenu.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-open::after,
.headerMenu.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-submenu-open::after,
.headerMenu.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-selected::after,
.headerMenu.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-submenu-selected::after,
.headerMenu.ant-menu-horizontal > .ant-menu-item::after,
.headerMenu.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected.headerMenuElement {
  background-color: rgba(0, 0, 0, 0.11);
}

.ant-menu-overflow {
  grid-gap: 16px;
  gap: 16px;
}

.headerMenu.ant-menu .headerMenuElement.ant-menu-item {
  background: rgba(46, 46, 180, 1);
  border-radius: 8px;
  padding: 14px 20px !important;
  height: 52px;
}

.barsMenu.ant-btn {
  background: transparent;
  display: inline-block;
  border: none;
  box-shadow: none;
}

.barsMenu.ant-btn::after {
  display: none;
}

.barsMenu.ant-btn:hover,
.barsMenu.ant-btn:focus {
  background: transparent;
  display: inline-block;
}

.barsMenu .bar1,
.barsMenu .bar2,
.barsMenu .bar3 {
  width: 51px;
  height: 2px;
  background-color: #ffffff;
  margin: 10px 0;
  transition: 0.4s;
}

.barsMenu.colored .bar1,
.barsMenu.colored .bar2,
.barsMenu.colored .bar3 {
  background-color: #2e2eb4;
}

.barsMenu .bar1 {
  margin-top: 0;
}

.barsOpen .bar1 {
  transform: rotate(-45deg) translate(-10px, 6px);
}

.barsOpen .bar2 {
  opacity: 0;
}

.barsOpen .bar3 {
  transform: rotate(45deg) translate(-9px, -8px);
}

.signOutModal .infoModalClose.ant-btn,
.signOutModal .infoModalDecline.ant-btn {
  margin: 0 5px;
}

.signOutModal {
  margin-bottom: -50px;
}

.signOutModal .infoModalDecline.ant-btn {
  background: #ffffff;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  color: #fc9c1c;
  padding: 19px 87px;
  height: auto;
  font-size: 18px;
  line-height: 22px;
  border: 0.5px solid #fc9c1c;
}

.signOutModal .infoModalDecline.ant-btn:hover {
  color: #2e2eb4;
  border: 0.5px solid #2e2eb4;
}

.headerMenu.ant-menu .headerMenuElement.ant-menu-item-selected {
  background-color: transparent;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.ant-avatar.ant-avatar-icon > .anticon {
  font-size: 24px;
}

.searchInputGroup.ant-input-group {
  display: flex;
  align-items: center;
}

.searchInput.ant-input-affix-wrapper {
  /* padding: 15px 30px;
  border: none;
  color: #c4c4c4;
  background: linear-gradient(90.14deg, #2e2eb4 0.03%, #2165ad 99.78%);
  height: 100px; */
  width: 611px;
  height: 55px;
  padding: 14px;
  border-radius: 300px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: rgba(255, 255, 255, 1);
}

.searchInput.ant-input-affix-wrapper .ant-input {
  background: transparent;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.searchInput.ant-input-affix-wrapper:not(
    .ant-input-affix-wrapper-disabled
  ):hover {
  border-color: transparent;
  box-shadow: none;
}

.searchInput .ant-input-suffix {
  cursor: pointer;
}

.headerTransparent .searchInput.ant-input-affix-wrapper {
  background: #f1f7fb;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
}

.headerTransparent .searchInput.ant-input-affix-wrapper .ant-input {
  color: #2e2eb4;
}

.searchCloseBtn {
  position: absolute;
  right: 10px;
  cursor: pointer;
  z-index: 1;
}

.headerTransparent .searchCloseBtn {
  filter: invert(100%);
}

.ant-menu-horizontal > .ant-menu-item .searchResultsItemLink {
  font-size: 18px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
  padding: 30px;
  display: block;
}

.ant-menu-horizontal > .ant-menu-item .searchResultsItemLink::before {
  display: none;
}

.headerMenu.ant-menu .searchMenuElement.ant-menu-item {
  background-color: rgba(255, 255, 255, 0);
  padding: 0 !important;
}

.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 300px !important;
  border-bottom-right-radius: 300px !important;
}

@media screen and (max-width: 390px) {
  .logoAdd {
    max-width: 101px;
  }

  .barsMenu .barHome {
    width: 16px;
    height: 1.5px;
    margin: 5px 0;
  }
  .barsMenu .bar1 {
    margin-top: 0;
  }
  .barsMenu .bar3 {
    margin-bottom: 0;
  }

  .barsHome.ant-btn {
    padding: 0;
  }
}

@media screen and (max-width: 1320px) {
  .headerMenu.ant-menu {
    min-width: 60%;
  }

  .header.ant-layout-header,
  .headerTransparent.ant-layout-header {
    padding: 20px 20px;
  }

  .additionStylesOnHomePage {
    padding: 0;
  }
}

@media screen and (max-width: 1200px) {
  .mobileMenu {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(90deg, #2e2eb4 0%, #216aad 100%);
    padding: 20px 0;
  }

  .simpleHeader .mobileMenu {
    background: linear-gradient(90deg, #2e2eb4 0%, #216aad 100%);
    padding-left: 30px;
  }

  .mobileMenu.open {
    display: block;
    top: 100px;
    box-shadow: 0 20px 10px rgb(0 0 0 / 50%);
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border: none;
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: none;
  }

  .headerMenu.ant-menu .headerMenuElement.ant-menu-item {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
    border-bottom: 0.5px solid #c4c4c4;
  }

  .headerMenu.ant-menu .headerMenuElement.ant-menu-item:last-child {
    border: none;
  }

  .headerTransparent.ant-layout-header
    .headerMenu.ant-menu
    .headerMenuElement.ant-menu-item
    .headerMenuElementLink {
    color: #ffffff;
  }
}

@media screen and (max-width: 1023px) {
  .header.ant-layout-header,
  .headerTransparent.ant-layout-header {
    padding: 20px;
  }

  .headerMenu.ant-menu-inline {
    min-width: auto;
    width: 100%;
  }

  .headerMenu.ant-menu-inline
    .headerMenuElement.ant-menu-item
    .headerMenuElementLink {
    font-family: Exo, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
  }

  .headerMenu.ant-menu-inline .headerSubmenu.ant-menu-submenu {
    flex-direction: column;
  }

  .headerMenu.ant-menu-inline
    .headerSubmenu.ant-menu-submenu
    .ant-menu-title-content {
    font-family: Exo, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #2e2eb4;
  }
}

@media only screen and (max-width: 600px) {
  .signOutModal .infoModalClose.ant-btn,
  .signOutModal .infoModalDecline.ant-btn {
    margin: 10px 0;
    width: 100%;
  }
}

@media only screen and (min-width: 1000px) {
  .headerTransparent .bar1,
  .headerTransparent .bar2,
  .headerTransparent .bar3 {
    background-color: #2e2eb4;
  }
}

.Auth_authWrapper__2WYje {
  height: 100%;
}

.Auth_logoImg__3nk6Q {
  width: 200px;
  height: 67px;
}

.Auth_logoTitle__2xviK {
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 85px;
  color: #ffffff;
  margin-left: 10px;
}

@media only screen and (max-width: 1023px) {
  .Auth_logoTitle__2xviK {
    color: #2e2eb4;
  }
}

@media only screen and (max-width: 600px) {
  .Auth_logoTitle__2xviK {
    font-size: 36px;
  }

  .Auth_logoImg__3nk6Q {
    width: 32px;
    height: 32px;
  }
}

.inputPassword.ant-form-item {
  position: relative;
}

.inputPassword .ant-form-item-control-input-content {
  border-bottom: 0.5px solid #5a5a5a;
  border-radius: 2px;
}

.forgotPassword {
  position: absolute;
  right: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #2e2eb4;
  padding-bottom: 0;
  line-height: 36px;
}

@media only screen and (max-width: 1140px) {
  .forgotPassword {
    font-size: 14px;
  }

  .inputPassword.ant-form-item .ant-input {
    width: calc(100% - 140px);
  }
}

@media only screen and (max-width: 600px) {
  .forgotPassword {
    font-size: 14px;
  }
}

.equalSideLayout,
.diffSideLayout {
  height: 100%;
  font-family: "Exo", sans-serif;
  margin: 0 auto;
  background: #f1f7fb;
}

.pageRow.ant-row {
  height: 100%;
}

.logoMobile,
.logoDesktop {
  position: absolute;
  top: 5%;
  left: 20%;
  z-index: 1;
  display: flex;
  align-items: center;
}

.equalSideLayout .leftWrapper.ant-col,
.equalSideLayout .leftWrapperMobile.ant-col,
.equalSideLayout .rightWrapper.ant-col {
  flex: 0 0 50%;
  max-width: 50%;
}

.diffSideLayout .leftWrapper.ant-col,
.diffSideLayout .leftWrapperMobile.ant-col {
  flex: 0 0 25%;
  max-width: 25%;
}

.diffSideLayout .rightWrapper.ant-col {
  flex: 0 0 75%;
  max-width: 75%;
}

.leftWrapper.ant-col,
.leftWrapperMobile.ant-col {
  background: linear-gradient(180deg, #2e2eb4 0%, #216aad 100%);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.rightWrapper.ant-col {
  background: #f1f7fb;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.createAccountBtn.ant-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  background-color: transparent;
  padding: 24px 60px;
  box-sizing: border-box;
  border-radius: 10px;
  height: auto;
}

.createAccountLink {
  height: 82px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.authForm.ant-form .ant-input {
  color: #5a5a5a;
  background: transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  border: 0;
  border-bottom: 0.5px solid #5a5a5a;
  padding: 5px 11px;
}

.authForm.ant-form .ant-input-affix-wrapper {
  color: #5a5a5a;
  background: transparent;
  border: 0;
  border-bottom: 0.5px solid #5a5a5a;
  padding: 0;
}

.authForm.ant-form .ant-input-affix-wrapper .ant-input {
  border: 0;
}

.authForm.ant-form .ant-input-affix-wrapper .ant-input-suffix {
  color: #c4c4c4;
  font-size: 14px;
  line-height: 19px;
}

.authForm.ant-form
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #5a5a5a;
}

.authForm.ant-form .ant-input-affix-wrapper:focus,
.authForm.ant-form .ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.authForm.ant-form
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: transparent;
  border: 0;
  border-bottom: 0.5px solid #5a5a5a;
  padding: 5px 11px;
  height: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.authForm.ant-form .ant-select-selection-placeholder {
  color: #5a5a5a;
}

.authForm.ant-form .ant-select-arrow {
  color: #5a5a5a;
  margin-top: -12px;
  font-size: 22px;
}

.authForm.ant-form
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #5a5a5a;
  box-shadow: none;
}

.authForm.ant-form
  .ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: transparent;
}

.authForm.ant-form
  .organizationSelect.ant-form-item-has-error
  .ant-form-item-control-input-content {
  border: 0;
}

.selectItem.ant-select-item {
  color: #5a5a5a;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  font-family: "Exo", sans-serif;
}

.selectItemRecord.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #2e2eb4;
}

.authForm.ant-form .ant-input:focus {
  box-shadow: none;
}

.authForm.ant-form .ant-input::placeholder {
  color: #5a5a5a;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.submitBtn.ant-btn {
  margin-top: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  background-color: #fc9c1c;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 10px;
  height: auto;
  width: 50%;
  white-space: break-spaces;
}

.authForm.ant-form {
  display: flex;
  flex-direction: column;
}

.authForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.authForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper,
.authForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.authForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:hover {
  background-color: transparent;
  border-color: transparent;
}

.authForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.authForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:focus,
.authForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
.authForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper-focused {
  border-color: transparent;
  box-shadow: none;
}

.authForm.ant-form
  .ant-form-item-has-error
  .ant-form-item-control-input-content {
  border-bottom: 0.5px solid #ff4d4f;
  border-radius: 2px;
}

.authForm.ant-form
  .inputCheckbox.ant-form-item-has-error
  .ant-form-item-control-input-content {
  border-bottom: 0;
}

.inputPassword.ant-form-item .ant-input {
  width: calc(100% - 170px);
  border: 0;
}

.diffSideLayout .rightWrapper.ant-col {
  min-height: 100%;
}

.pageTitle {
  display: block;
  margin-bottom: 80px;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 48px;
  color: #000000;
}

.sideSignUp,
.sideSignIn,
.sideForgotPass {
  white-space: break-spaces;
}

.sideSignUp {
  margin-bottom: 224px;
}

.sideSignIn {
  margin-bottom: 160px;
}

.sideForgotPass {
  margin-bottom: 100px;
}

@media only screen and (max-width: 1400px) {
  .diffSideLayout .logoDesktop {
    left: 60px;
  }
}

@media only screen and (max-width: 1200px) {
  .diffSideLayout .logoDesktop {
    left: 30px;
  }

  .submitBtn.ant-btn {
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .logoMobile {
    display: none;
  }

  .leftWrapper.desktop.ant-col {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  /* .diffSideLayout .pageTitle {
        margin-top: 0;
    } */

  .equalSideLayout .pageTitle {
    margin-top: 80px;
  }

  .sideSignUp,
  .sideSignIn,
  .sideForgotPass {
    margin-bottom: 0;
  }

  .logoDesktop {
    display: none;
  }

  .logoMobile {
    top: 30px;
    left: 30px;
  }

  .createAccountBtn.ant-btn {
    width: 100%;
    padding: 24px;
  }

  .createAccountLink {
    margin-top: 40px;
    width: 100%;
  }

  .leftWrapperMobile.ant-col {
    display: none;
  }

  .leftWrapper.ant-col,
  .leftWrapperMobile.ant-col {
    align-items: center;
  }

  .equalSideLayout .leftWrapper.ant-col,
  .equalSideLayout .rightWrapper.ant-col,
  .diffSideLayout .leftWrapper.ant-col,
  .diffSideLayout .rightWrapper.ant-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .authForm.ant-form {
    width: 100%;
  }

  .submitBtn.ant-btn {
    width: 100%;
    padding: 24px 0;
  }

  .pageTitle {
    width: 100%;
  }

  .diffSideLayout .pageTitle,
  .equalSideLayout .pageTitle {
    margin-bottom: 50px;
    margin-top: 130px;
  }
}

.AuthLayout_leftWrapperContainer__38BJ4,
.AuthLayout_rightWrapperContainer__9tOqH {
  max-width: 960px;
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0 auto;
}

.AuthLayout_leftWrapperContainer__38BJ4 {
  margin: auto 0 auto auto;
  padding: 10% 0 10% 100px;
}

.AuthLayout_rightWrapperContainer__9tOqH {
  padding: 10% 0;
}

.AuthLayout_leftWrapperContent__pfHtf {
  padding: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}

.AuthLayout_leftWrapperTitle__1kIxm {
  margin-bottom: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 48px;
  color: #ffffff;
}

.AuthLayout_leftWrapperText__2v1UF {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  display: block;
  width: 75%;
}

.AuthLayout_rightWrapperContent__2hutQ {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 10%;
  min-height: 100%;
}

@media only screen and (max-width: 1023px) {
  .AuthLayout_leftWrapperContent__pfHtf {
    padding: 70px 30px 70px;
    margin: 0;
  }

  .AuthLayout_rightWrapperContent__2hutQ {
    padding: 15%;
    margin: 0;
  }

  .AuthLayout_leftWrapperContainer__38BJ4 {
    margin: 0;
    padding: 0;
  }

  .AuthLayout_rightWrapperContainer__9tOqH {
    margin: 0;
    padding: 0;
  }
}

@media only screen and (max-width: 600px) {
  .AuthLayout_rightWrapperContent__2hutQ {
    padding: 10%;
    margin: 0;
  }
}

.ForgotPassword_backLink__3K-CT {
  width: auto;
  position: absolute;
  top: 7%;
  left: 110px;
  font-size: 24px;
  line-height: 32px;
  color: #5a5a5a;
  padding-left: 30px;
}

.ForgotPassword_backLink__3K-CT:hover,
.ForgotPassword_backLink__3K-CT:hover:before {
  color: #2e2eb4;
}

.ForgotPassword_backLink__3K-CT::before {
  content: "";
  color: #5a5a5a;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  position: absolute;
  width: 11px;
  height: 11px;
  left: 5px;
  top: 10px;
  transform: rotate(135deg);
}

@media only screen and (max-width: 1023px) {
  .ForgotPassword_backLink__3K-CT {
    top: 20%;
  }
}

@media only screen and (max-width: 600px) {
  .ForgotPassword_backLink__3K-CT {
    top: 150px;
    left: 30px;
  }
}

.stepsCount {
  width: 100%;
  position: absolute;
  top: -5%;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
}

.stepBack {
  font-size: 24px;
  line-height: 32px;
  color: #5a5a5a;
  padding: 0 0 0 18px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.stepBack:hover,
.stepBack:active,
.stepBack:focus,
.stepBack:hover:before {
  color: #2e2eb4;
}

.stepBack::before {
  content: "";
  color: #5a5a5a;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  position: absolute;
  width: 11px;
  height: 11px;
  left: 100px;
  top: 10px;
  transform: rotate(135deg);
}

.termsShow {
  font-size: 14px;
  line-height: 19px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #2e2eb4;
  padding-left: 10px;
  cursor: pointer;
  position: relative;
}

.termsPromt {
  font-size: 10px;
  line-height: 15px;
  color: #ff4d4f;
  padding-left: 10px;
}

.termsShow:before {
  content: "";
  position: absolute;
  display: block;
  left: -40px;
  top: -5px;
  right: -5px;
  bottom: -5px;
}

.termsShow:hover {
  color: #2168ad;
  text-decoration: none;
}

.counter {
  font-size: 24px;
  line-height: 32px;
  color: #5a5a5a;
  margin-left: auto;
}

.inputCheckbox.ant-form-item {
  margin-top: 0;
}

.inputCheckbox .ant-checkbox-inner {
  width: 30px;
  height: 30px;
  border: 1px solid #5a5a5a;
  border-radius: 5px;
}

.inputCheckbox .ant-checkbox-checked::after {
  border-radius: 5px;
}

.inputCheckbox .ant-checkbox-inner::after {
  width: 10px;
  height: 17px;
}

.inputCheckbox .ant-checkbox-wrapper {
  align-items: center;
}

@media only screen and (max-width: 1330px) {
  .stepBack::before {
    left: 10%;
  }
}

@media only screen and (max-width: 1023px) {
  .stepsCount {
    top: 8%;
    padding: 0 15%;
  }

  .stepBack::before {
    left: 5%;
  }

  .stepBack {
    top: 250%;
    position: absolute;
  }

  .pageTitle {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 800px) {
  .pageTitle {
    margin-bottom: 50px;
    margin-top: 90px;
  }
}

@media only screen and (max-width: 600px) {
  .stepsCount {
    padding: 0 10%;
    position: initial;
  }

  .termsShow {
    padding-left: 0;
  }

  .inputCheckbox .ant-checkbox-wrapper {
    align-items: center;
  }

  .counter {
    position: absolute;
    right: 10%;
    top: 7%;
  }

  .stepBack {
    top: 20%;
    position: absolute;
    left: 10%;
  }
}

.Terms_termsWrapper__WL-GV {
  height: 75vh;
  margin: 0 190px;
  background: #ffffff;
}

.Terms_termsContainer__17dgD {
  padding: 35px 35px 80px;
  height: 100%;
}

.Terms_termsContent__wQxw_ {
  max-height: calc(100% - 80px);
  overflow-x: auto;
}

.Terms_checkboxTerms___hbuU {
  margin: 30px 0;
  color: #2e2eb4;
}

@media only screen and (min-width: 1201px) {
  .Terms_termsWrapper__WL-GV {
    width: calc(100% - 380px);
  }
}

@media only screen and (max-width: 1200px) {
  .Terms_termsWrapper__WL-GV {
    margin: 0 30px;
    width: calc(100% - 60px);
  }
}

@media only screen and (max-width: 1023px) {
  .Terms_termsWrapper__WL-GV {
    margin: 5% 30px;
  }
}

@media only screen and (max-width: 600px) {
  .Terms_termsWrapper__WL-GV {
    margin: 0;
    width: 100%;
    height: 100vh;
  }
}

.awlist1 {
  list-style: none;
  counter-reset: awlistcounter3_1;
}

.awlist1 > li:before {
  content: "(" counter(awlistcounter3_1, lower-roman) ")";
  counter-increment: awlistcounter3_1;
}

.termsTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #2e2eb4;
  padding-bottom: 30px;
}

.close {
  width: 18px;
  height: 18px;
  cursor: pointer;
  background-color: #f1f7fb00;
  border: none;
}

.close:hover {
  opacity: 0.3;
}

.close:before,
.close:after {
  position: absolute;
  content: " ";
  height: 18px;
  width: 2px;
  background-color: #333;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.AppSpinner_spinnerWrapper__1QIBk {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-modal-centered .infoModal.ant-modal {
  text-align: center;
  font-family: Inter, sans-serif;
}

.infoModalClose.ant-btn {
  background: #fc9c1c;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  color: #ffffff;
  padding: 19px 0;
  height: auto;
  font-size: 18px;
  line-height: 22px;
  max-width: 220px;
  width: 100%;
}

.infoModal.ant-modal .ant-modal-body {
  padding: 30px;
}

.contactModal.ant-modal {
  width: 697px !important;
}

.contactModal.ant-modal .ant-modal-body {
  padding: 0;
}

.contactModal.ant-modal .ant-modal-body,
.contactModal.ant-modal .ant-modal-content {
  background-color: rgba(46, 46, 180, 1);
  border-radius: 32px;
}

.contactModal.ant-modal .ant-modal-close {
  background: rgba(255, 255, 255, 0.4);
  width: 60px;
  height: 60px;
  padding: 18px;
  border-radius: 300px;
  position: absolute;
  top: 5%;
  right: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactModal.ant-modal .ant-modal-close:hover {
  background: rgba(217, 217, 217, 1);
}

.contactModal.ant-modal .ant-modal-close-x {
  font-size: 30px;
  font-weight: 200;
}

.contactModal.ant-modal .ant-modal-close .ant-modal-close-icon svg {
  fill: #ffffff;
}

.infoModalTitle {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 20px;
}

.infoModalContent {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 50px;
  white-space: pre-wrap;
}

.NotFound_wrapper__Gcq2B {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.NotFound_wrapper__Gcq2B h1 {
  font-size: 68px;
  margin-bottom: 20px;
}

.NotFound_wrapper__Gcq2B p {
  font-size: 20px;
}

