.ant-modal-centered .infoModal.ant-modal {
  text-align: center;
  font-family: Inter, sans-serif;
}

.infoModalClose.ant-btn {
  background: #fc9c1c;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  color: #ffffff;
  padding: 19px 0;
  height: auto;
  font-size: 18px;
  line-height: 22px;
  max-width: 220px;
  width: 100%;
}

.infoModal.ant-modal .ant-modal-body {
  padding: 30px;
}

.contactModal.ant-modal {
  width: 697px !important;
}

.contactModal.ant-modal .ant-modal-body {
  padding: 0;
}

.contactModal.ant-modal .ant-modal-body,
.contactModal.ant-modal .ant-modal-content {
  background-color: rgba(46, 46, 180, 1);
  border-radius: 32px;
}

.contactModal.ant-modal .ant-modal-close {
  background: rgba(255, 255, 255, 0.4);
  width: 60px;
  height: 60px;
  padding: 18px;
  border-radius: 300px;
  position: absolute;
  top: 5%;
  right: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactModal.ant-modal .ant-modal-close:hover {
  background: rgba(217, 217, 217, 1);
}

.contactModal.ant-modal .ant-modal-close-x {
  font-size: 30px;
  font-weight: 200;
}

.contactModal.ant-modal .ant-modal-close .ant-modal-close-icon svg {
  fill: #ffffff;
}

.infoModalTitle {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 20px;
}

.infoModalContent {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 50px;
  white-space: pre-wrap;
}
