.authWrapper {
  height: 100%;
}

.logoImg {
  width: 200px;
  height: 67px;
}

.logoTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 85px;
  color: #ffffff;
  margin-left: 10px;
}

@media only screen and (max-width: 1023px) {
  .logoTitle {
    color: #2e2eb4;
  }
}

@media only screen and (max-width: 600px) {
  .logoTitle {
    font-size: 36px;
  }

  .logoImg {
    width: 32px;
    height: 32px;
  }
}
