.header.ant-layout-header,
.headerTransparent.ant-layout-header {
  display: flex;
  justify-content: space-between;
  /*padding: 20px 100px;*/
  padding: 0;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  align-items: center;
}

.header.ant-layout-header {
  background: linear-gradient(90deg, #2e2eb4 0%, #216aad 100%);
}

.additionStylesOnHomePage {
  padding: 22px 38px 18px;
  max-width: 1900px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  align-items: center;
}

.simpleHeader.ant-layout-header {
  background: linear-gradient(90deg, #2e2eb4 0%, #216aad 100%);
}

.headerWithLine.ant-layout-header {
  border-bottom: 1px solid #ffffff;
  height: 120px;
}

.logoAdd {
  cursor: pointer;
  max-width: 310px;
  width: 100%;
}

.logoAdd .logoTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 85px;
  color: #ffffff;
  margin-left: 9px;
}

.header.ant-layout-header .searchIcon {
  color: #ffffff;
}

.headerTransparent.ant-layout-header {
  background: transparent;
}

.headerTransparent.ant-layout-header .searchIcon {
  color: #2e2eb4;
}

.headerMenu.ant-menu {
  background: transparent;
  border-bottom: none;
  justify-content: flex-end;
  align-items: center;
}

.headerMenuHome.ant-menu {
  background: transparent;
  border-bottom: none;
}

.headerMenuHome.ant-menu-inline
  .headerSubmenu.ant-menu-submenu
  .ant-menu-title-content::after {
  display: none;
}

.headerTransparent.ant-layout-header
  .headerMenu.ant-menu
  .headerMenuElement.ant-menu-item
  .headerMenuElementLink {
  color: #2e2eb4;
}

.headerMenu.ant-menu .headerMenuElement.ant-menu-item,
.headerMenu.ant-menu .headerSubmenu.ant-menu-submenu {
  display: flex;
  align-items: center;
}

.headerMenuElementHome.ant-menu-item {
  margin-right: 32px;
  padding: 0 !important;
}

.headerMenuElementHome.ant-menu-item:hover::after,
.headerSubmenu.ant-menu-submenu:hover::after {
  display: none;
}

.headerMenuElementHome.ant-menu-item:last-child,
.headerMenuElementHome.ant-menu-item:nth-last-child(4),
.headerMenuElementHome.ant-menu-item:nth-last-child(3),
.headerMenuElementHome.ant-menu-item:nth-last-child(2) {
  margin-right: 0;
}

.headerMenu.ant-menu .headerMenuElement.ant-menu-item.signInBtn {
  padding: 0 0 0 29px;
}

.headerMenu.ant-menu .headerMenuElement.ant-menu-item.signUpBtn {
  padding: 0 29px;
}

.headerMenu.ant-menu .headerSubmenu.ant-menu-submenu {
  padding: 0;
}

.ant-menu-submenu-popup > .ant-menu {
  background: linear-gradient(180deg, #2168ad 0%, #2e2eb4 100%);
}

.ant-menu-submenu-popup > .ant-menu .ant-menu-title-content {
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 35px;
  color: #ffffff;
}

.headerMenu.ant-menu .headerMenuElement.ant-menu-item:hover,
.headerMenu.ant-menu .headerMenuElement.ant-menu-item-active {
  background-color: rgba(63, 63, 239, 1);
}

.headerMenu.ant-menu .headerMenuElement.searchMenuElement:hover {
  background-color: transparent;
}

.ant-menu-horizontal::after {
  display: none !important;
}

.headerMenu.ant-menu .headerMenuElement.signUpBtn.ant-menu-item:hover,
.headerMenu.ant-menu .headerMenuElement.signUpBtn.ant-menu-item-active {
  background-color: rgba(0, 0, 0, 0);
}

.headerMenu.ant-menu .headerMenuElement.signInBtn.ant-menu-item:hover,
.headerMenu.ant-menu .headerMenuElement.signInBtn.ant-menu-item-active {
  background-color: rgba(0, 0, 0, 0);
}

.headerMenuElement.ant-menu-item .headerMenuElementLink {
  color: rgba(255, 255, 255, 1);
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
}

.headerMenuElement.ant-menu-item .homeLinkElement {
  font-weight: 400;
  line-height: 24px;
  font-size: 18px;
  padding: 0 19px;
}

.headerMenuElement.ant-menu-item .signUpBtn {
  padding: 5px 11px;
  text-align: center;
  border-radius: 10px;
  background: #fc9c1c;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  color: #ffffff;
}

.headerMenuElement.ant-menu-item .signInBtn {
  padding: 5px 11px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.08));
  color: #ffffff;
}

.headerMenuElement.ant-menu-item .headerMenuElementLink:hover {
  color: #ffffff;
}

.headerMenuElement.ant-menu-item .signUpBtn:hover {
  color: #2e2eb4;
}

.headerMenuElement.ant-menu-item .signInBtn:hover {
  color: #fc9c1c;
  border: 1px solid #fc9c1c;
}

.headerMenuElement.ant-menu-item:hover::after,
.headerSubmenu.ant-menu-submenu:hover::after {
  display: none;
}

.headerMenu.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item:hover::after,
.headerMenu.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-submenu:hover::after,
.headerMenu.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-active::after,
.headerMenu.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-submenu-active::after,
.headerMenu.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-open::after,
.headerMenu.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-submenu-open::after,
.headerMenu.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-selected::after,
.headerMenu.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-submenu-selected::after,
.headerMenu.ant-menu-horizontal > .ant-menu-item::after,
.headerMenu.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected.headerMenuElement {
  background-color: rgba(0, 0, 0, 0.11);
}

.ant-menu-overflow {
  gap: 16px;
}

.headerMenu.ant-menu .headerMenuElement.ant-menu-item {
  background: rgba(46, 46, 180, 1);
  border-radius: 8px;
  padding: 14px 20px !important;
  height: 52px;
}

.barsMenu.ant-btn {
  background: transparent;
  display: inline-block;
  border: none;
  box-shadow: none;
}

.barsMenu.ant-btn::after {
  display: none;
}

.barsMenu.ant-btn:hover,
.barsMenu.ant-btn:focus {
  background: transparent;
  display: inline-block;
}

.barsMenu .bar1,
.barsMenu .bar2,
.barsMenu .bar3 {
  width: 51px;
  height: 2px;
  background-color: #ffffff;
  margin: 10px 0;
  transition: 0.4s;
}

.barsMenu.colored .bar1,
.barsMenu.colored .bar2,
.barsMenu.colored .bar3 {
  background-color: #2e2eb4;
}

.barsMenu .bar1 {
  margin-top: 0;
}

.barsOpen .bar1 {
  -webkit-transform: rotate(-45deg) translate(-10px, 6px);
  transform: rotate(-45deg) translate(-10px, 6px);
}

.barsOpen .bar2 {
  opacity: 0;
}

.barsOpen .bar3 {
  -webkit-transform: rotate(45deg) translate(-9px, -8px);
  transform: rotate(45deg) translate(-9px, -8px);
}

.signOutModal .infoModalClose.ant-btn,
.signOutModal .infoModalDecline.ant-btn {
  margin: 0 5px;
}

.signOutModal {
  margin-bottom: -50px;
}

.signOutModal .infoModalDecline.ant-btn {
  background: #ffffff;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  color: #fc9c1c;
  padding: 19px 87px;
  height: auto;
  font-size: 18px;
  line-height: 22px;
  border: 0.5px solid #fc9c1c;
}

.signOutModal .infoModalDecline.ant-btn:hover {
  color: #2e2eb4;
  border: 0.5px solid #2e2eb4;
}

.headerMenu.ant-menu .headerMenuElement.ant-menu-item-selected {
  background-color: transparent;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.ant-avatar.ant-avatar-icon > .anticon {
  font-size: 24px;
}

.searchInputGroup.ant-input-group {
  display: flex;
  align-items: center;
}

.searchInput.ant-input-affix-wrapper {
  /* padding: 15px 30px;
  border: none;
  color: #c4c4c4;
  background: linear-gradient(90.14deg, #2e2eb4 0.03%, #2165ad 99.78%);
  height: 100px; */
  width: 611px;
  height: 55px;
  padding: 14px;
  border-radius: 300px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: rgba(255, 255, 255, 1);
}

.searchInput.ant-input-affix-wrapper .ant-input {
  background: transparent;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.searchInput.ant-input-affix-wrapper:not(
    .ant-input-affix-wrapper-disabled
  ):hover {
  border-color: transparent;
  box-shadow: none;
}

.searchInput .ant-input-suffix {
  cursor: pointer;
}

.headerTransparent .searchInput.ant-input-affix-wrapper {
  background: #f1f7fb;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
}

.headerTransparent .searchInput.ant-input-affix-wrapper .ant-input {
  color: #2e2eb4;
}

.searchCloseBtn {
  position: absolute;
  right: 10px;
  cursor: pointer;
  z-index: 1;
}

.headerTransparent .searchCloseBtn {
  filter: invert(100%);
}

.ant-menu-horizontal > .ant-menu-item .searchResultsItemLink {
  font-size: 18px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
  padding: 30px;
  display: block;
}

.ant-menu-horizontal > .ant-menu-item .searchResultsItemLink::before {
  display: none;
}

.headerMenu.ant-menu .searchMenuElement.ant-menu-item {
  background-color: rgba(255, 255, 255, 0);
  padding: 0 !important;
}

.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 300px !important;
  border-bottom-right-radius: 300px !important;
}

@media screen and (max-width: 390px) {
  .logoAdd {
    max-width: 101px;
  }

  .barsMenu .barHome {
    width: 16px;
    height: 1.5px;
    margin: 5px 0;
  }
  .barsMenu .bar1 {
    margin-top: 0;
  }
  .barsMenu .bar3 {
    margin-bottom: 0;
  }

  .barsHome.ant-btn {
    padding: 0;
  }
}

@media screen and (max-width: 1320px) {
  .headerMenu.ant-menu {
    min-width: 60%;
  }

  .header.ant-layout-header,
  .headerTransparent.ant-layout-header {
    padding: 20px 20px;
  }

  .additionStylesOnHomePage {
    padding: 0;
  }
}

@media screen and (max-width: 1200px) {
  .mobileMenu {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(90deg, #2e2eb4 0%, #216aad 100%);
    padding: 20px 0;
  }

  .simpleHeader .mobileMenu {
    background: linear-gradient(90deg, #2e2eb4 0%, #216aad 100%);
    padding-left: 30px;
  }

  .mobileMenu.open {
    display: block;
    top: 100px;
    box-shadow: 0 20px 10px rgb(0 0 0 / 50%);
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border: none;
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: none;
  }

  .headerMenu.ant-menu .headerMenuElement.ant-menu-item {
    height: fit-content;
    padding: 10px;
    border-bottom: 0.5px solid #c4c4c4;
  }

  .headerMenu.ant-menu .headerMenuElement.ant-menu-item:last-child {
    border: none;
  }

  .headerTransparent.ant-layout-header
    .headerMenu.ant-menu
    .headerMenuElement.ant-menu-item
    .headerMenuElementLink {
    color: #ffffff;
  }
}

@media screen and (max-width: 1023px) {
  .header.ant-layout-header,
  .headerTransparent.ant-layout-header {
    padding: 20px;
  }

  .headerMenu.ant-menu-inline {
    min-width: auto;
    width: 100%;
  }

  .headerMenu.ant-menu-inline
    .headerMenuElement.ant-menu-item
    .headerMenuElementLink {
    font-family: Exo, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
  }

  .headerMenu.ant-menu-inline .headerSubmenu.ant-menu-submenu {
    flex-direction: column;
  }

  .headerMenu.ant-menu-inline
    .headerSubmenu.ant-menu-submenu
    .ant-menu-title-content {
    font-family: Exo, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #2e2eb4;
  }
}

@media only screen and (max-width: 600px) {
  .signOutModal .infoModalClose.ant-btn,
  .signOutModal .infoModalDecline.ant-btn {
    margin: 10px 0;
    width: 100%;
  }
}

@media only screen and (min-width: 1000px) {
  .headerTransparent .bar1,
  .headerTransparent .bar2,
  .headerTransparent .bar3 {
    background-color: #2e2eb4;
  }
}
