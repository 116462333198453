.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper h1 {
  font-size: 68px;
  margin-bottom: 20px;
}

.wrapper p {
  font-size: 20px;
}
