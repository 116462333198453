.equalSideLayout,
.diffSideLayout {
  height: 100%;
  font-family: "Exo", sans-serif;
  margin: 0 auto;
  background: #f1f7fb;
}

.pageRow.ant-row {
  height: 100%;
}

.logoMobile,
.logoDesktop {
  position: absolute;
  top: 5%;
  left: 20%;
  z-index: 1;
  display: flex;
  align-items: center;
}

.equalSideLayout .leftWrapper.ant-col,
.equalSideLayout .leftWrapperMobile.ant-col,
.equalSideLayout .rightWrapper.ant-col {
  flex: 0 0 50%;
  max-width: 50%;
}

.diffSideLayout .leftWrapper.ant-col,
.diffSideLayout .leftWrapperMobile.ant-col {
  flex: 0 0 25%;
  max-width: 25%;
}

.diffSideLayout .rightWrapper.ant-col {
  flex: 0 0 75%;
  max-width: 75%;
}

.leftWrapper.ant-col,
.leftWrapperMobile.ant-col {
  background: linear-gradient(180deg, #2e2eb4 0%, #216aad 100%);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.rightWrapper.ant-col {
  background: #f1f7fb;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.createAccountBtn.ant-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  background-color: transparent;
  padding: 24px 60px;
  box-sizing: border-box;
  border-radius: 10px;
  height: auto;
}

.createAccountLink {
  height: 82px;
  width: fit-content;
}

.authForm.ant-form .ant-input {
  color: #5a5a5a;
  background: transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  border: 0;
  border-bottom: 0.5px solid #5a5a5a;
  padding: 5px 11px;
}

.authForm.ant-form .ant-input-affix-wrapper {
  color: #5a5a5a;
  background: transparent;
  border: 0;
  border-bottom: 0.5px solid #5a5a5a;
  padding: 0;
}

.authForm.ant-form .ant-input-affix-wrapper .ant-input {
  border: 0;
}

.authForm.ant-form .ant-input-affix-wrapper .ant-input-suffix {
  color: #c4c4c4;
  font-size: 14px;
  line-height: 19px;
}

.authForm.ant-form
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #5a5a5a;
}

.authForm.ant-form .ant-input-affix-wrapper:focus,
.authForm.ant-form .ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.authForm.ant-form
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: transparent;
  border: 0;
  border-bottom: 0.5px solid #5a5a5a;
  padding: 5px 11px;
  height: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.authForm.ant-form .ant-select-selection-placeholder {
  color: #5a5a5a;
}

.authForm.ant-form .ant-select-arrow {
  color: #5a5a5a;
  margin-top: -12px;
  font-size: 22px;
}

.authForm.ant-form
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #5a5a5a;
  box-shadow: none;
}

.authForm.ant-form
  .ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: transparent;
}

.authForm.ant-form
  .organizationSelect.ant-form-item-has-error
  .ant-form-item-control-input-content {
  border: 0;
}

.selectItem.ant-select-item {
  color: #5a5a5a;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  font-family: "Exo", sans-serif;
}

.selectItemRecord.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #2e2eb4;
}

.authForm.ant-form .ant-input:focus {
  box-shadow: none;
}

.authForm.ant-form .ant-input::placeholder {
  color: #5a5a5a;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.submitBtn.ant-btn {
  margin-top: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  background-color: #fc9c1c;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 10px;
  height: auto;
  width: 50%;
  white-space: break-spaces;
}

.authForm.ant-form {
  display: flex;
  flex-direction: column;
}

.authForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.authForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper,
.authForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.authForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:hover {
  background-color: transparent;
  border-color: transparent;
}

.authForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.authForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:focus,
.authForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
.authForm.ant-form
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper-focused {
  border-color: transparent;
  box-shadow: none;
}

.authForm.ant-form
  .ant-form-item-has-error
  .ant-form-item-control-input-content {
  border-bottom: 0.5px solid #ff4d4f;
  border-radius: 2px;
}

.authForm.ant-form
  .inputCheckbox.ant-form-item-has-error
  .ant-form-item-control-input-content {
  border-bottom: 0;
}

.inputPassword.ant-form-item .ant-input {
  width: calc(100% - 170px);
  border: 0;
}

.diffSideLayout .rightWrapper.ant-col {
  min-height: 100%;
}

.pageTitle {
  display: block;
  margin-bottom: 80px;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 48px;
  color: #000000;
}

.sideSignUp,
.sideSignIn,
.sideForgotPass {
  white-space: break-spaces;
}

.sideSignUp {
  margin-bottom: 224px;
}

.sideSignIn {
  margin-bottom: 160px;
}

.sideForgotPass {
  margin-bottom: 100px;
}

@media only screen and (max-width: 1400px) {
  .diffSideLayout .logoDesktop {
    left: 60px;
  }
}

@media only screen and (max-width: 1200px) {
  .diffSideLayout .logoDesktop {
    left: 30px;
  }

  .submitBtn.ant-btn {
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .logoMobile {
    display: none;
  }

  .leftWrapper.desktop.ant-col {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  /* .diffSideLayout .pageTitle {
        margin-top: 0;
    } */

  .equalSideLayout .pageTitle {
    margin-top: 80px;
  }

  .sideSignUp,
  .sideSignIn,
  .sideForgotPass {
    margin-bottom: 0;
  }

  .logoDesktop {
    display: none;
  }

  .logoMobile {
    top: 30px;
    left: 30px;
  }

  .createAccountBtn.ant-btn {
    width: 100%;
    padding: 24px;
  }

  .createAccountLink {
    margin-top: 40px;
    width: 100%;
  }

  .leftWrapperMobile.ant-col {
    display: none;
  }

  .leftWrapper.ant-col,
  .leftWrapperMobile.ant-col {
    align-items: center;
  }

  .equalSideLayout .leftWrapper.ant-col,
  .equalSideLayout .rightWrapper.ant-col,
  .diffSideLayout .leftWrapper.ant-col,
  .diffSideLayout .rightWrapper.ant-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .authForm.ant-form {
    width: 100%;
  }

  .submitBtn.ant-btn {
    width: 100%;
    padding: 24px 0;
  }

  .pageTitle {
    width: 100%;
  }

  .diffSideLayout .pageTitle,
  .equalSideLayout .pageTitle {
    margin-bottom: 50px;
    margin-top: 130px;
  }
}
